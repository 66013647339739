import { Grid } from '@mui/material'
import React from 'react'

import LoginForm from '../login-form'
import { RootContainer, LoginFormGridItem } from './styles'

export default function LoginPage(): JSX.Element {
  return (
    <RootContainer maxWidth="xl">
      <Grid item container>
        <Grid item xs={12} lg={1}></Grid>
        <LoginFormGridItem item xs={12} md={3} lg={3}>
          <LoginForm />
        </LoginFormGridItem>
      </Grid>
    </RootContainer>
  )
}
