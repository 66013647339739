import { styled, Grid } from '@mui/material'

import { colors } from '../../../theme'

export const classes = {
  root: {
    borderRadius: '8px',
    padding: '1.8rem 2.0rem 1.0rem',
    backgroundColor: colors.background.white,
    boxShadow: `0px 3px 20px ${colors.boxShadow.default}`,
    color: colors.text.paragraph,
    border: '1px',
    borderStyle: 'solid',
    borderColor: colors.border.solid,
  },
  loginHeader: {
    marginTop: '48px',
    marginBottom: '8px',
  },
  input: {
    marginBottom: '0.6rem',
  },
  forgotPasswordBox: {
    textAlign: 'end',
  },
}

export const StyledGridItem = styled(Grid)({
  padding: '24px',
})
