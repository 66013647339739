import { Avatar } from '@mui/material'
import { green, red } from '@mui/material/colors'
import React from 'react'

import { OriginatorEntity } from '../../../applyproof'

export type AccountAvatarProps = {
  originator?: OriginatorEntity | undefined
}

export default function OriginatorAvatar({
  originator,
}: AccountAvatarProps): JSX.Element {
  const backgroundColor = originator?.enabled ? green[500] : red[500]
  return (
    <Avatar
      aria-label="profile"
      alt={originator?.organization ?? ''}
      style={{ backgroundColor }}
    >
      {originator?.organization ? originator?.organization[0] ?? '' : ''}
    </Avatar>
  )
}
