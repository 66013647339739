import { Alert, Snackbar, SnackbarOrigin } from '@mui/material'
import React from 'react'

export type ApAlertEvent = {
  message: string
}
export type ApAlertEventHandler = (event: ApAlertEvent) => void

export type AlertState = {
  open?: boolean
  message: string
  type?: string
}

export type ApAlertProps = AlertState & {
  onClose?: () => void
}

type AlertType = 'success' | 'info' | 'error' | 'warning'

export default function AppAlert({
  open = false,
  message,
  type = 'success',
  onClose = () => undefined,
}: ApAlertProps): JSX.Element {
  const anchorOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'right',
  }

  const alertType = type as AlertType

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      autoHideDuration={6000}
      open={open}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={alertType}>
        {message}
      </Alert>
    </Snackbar>
  )
}
