import {
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import * as React from 'react'

type ConfirmationDialogStyle = 'default' | 'green' | 'hazardous'

type ConfirmationDialogProps = {
  style?: ConfirmationDialogStyle
  loading?: boolean
  title: string
  text: string
  open?: boolean
  onCancel: () => void
  onAccept: () => void
}

function colorFromStyle(style: ConfirmationDialogStyle): string | undefined {
  switch (style) {
    case 'green':
      return green[500]
    case 'hazardous':
      return red[500]
    default:
      return undefined
  }
}

export default function ConfirmationDialog({
  style = 'default',
  loading,
  title,
  text,
  open,
  onCancel,
  onAccept,
}: ConfirmationDialogProps): JSX.Element {
  const color = colorFromStyle(style)
  return (
    <Dialog
      open={open ?? false}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ color }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="text">
          Cancel
        </Button>
        <Button
          style={{ backgroundColor: color }}
          onClick={onAccept}
          autoFocus
          endIcon={loading ? <CircularProgress size={12} /> : undefined}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
