import {
  OpenInNew as OpenInNewIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import {
  Button,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  createTheme,
} from '@mui/material'
import React, { Fragment } from 'react'

import { OriginatorEntity } from '../../../applyproof'
import OriginatorAvatar from '../originator-avatar'
import OriginatorDetails from '../originator-details'

const theme = createTheme()
const classes = {
  root: {},
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}

export type QuickOriginatorInfoType = {
  loading?: boolean
  originator: OriginatorEntity | undefined
  onClose: () => void
}

export default function QuickOriginatorInfo({
  originator,
  onClose,
}: QuickOriginatorInfoType): JSX.Element {
  const cardContent = originator ? (
    <Fragment>
      <CardHeader
        avatar={<OriginatorAvatar originator={originator} />}
        title={originator.organization}
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <OriginatorDetails originator={originator} />
      </CardContent>
      <CardActions disableSpacing>
        <Grid container spacing={1}>
          <Grid item style={{ flex: 2 }}></Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              endIcon={<OpenInNewIcon />}
              href={`/originators/${encodeURIComponent(originator.id)}`}
            >
              View Account
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Fragment>
  ) : undefined

  return <Card sx={classes.root}>{cardContent}</Card>
}
