import Typography from '@mui/material/Typography'
import React, { useContext } from 'react'

import { ApplyProofUser } from '../../../applyproof'
import { originatorLabelByGuid } from '../../../applyproof/originators'
import { colors } from '../../../theme/color'
import { OriginatorContext } from '../../originator-context'

export type AccountDetailsProps = {
  user: ApplyProofUser
}

export default function AccountDetails({
  user,
}: AccountDetailsProps): JSX.Element {
  const originators = useContext(OriginatorContext)
  return (
    <>
      {user.enabled ? (
        <Typography
          variant="body2"
          style={{ color: colors.accent.green.light }}
          component="p"
        >
          Enabled
        </Typography>
      ) : (
        <Typography
          variant="body2"
          style={{ color: colors.accent.red.light }}
          component="p"
        >
          Disabled
        </Typography>
      )}
      <Typography variant="body2" color="textSecondary" component="p">
        Email: {user.email}
      </Typography>
      {/* <Typography variant="body2" color="textSecondary" component="p">
        UserId: {user.username}
      </Typography> */}
      <Typography variant="body2" color="textSecondary" component="p">
        Status: {user.confirmationStatus}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        Account: {originatorLabelByGuid(originators, user.accountId)}
      </Typography>
    </>
  )
}
