import * as yup from 'yup'

const nameRegExp = new RegExp('.*[^\\s].*')

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Enter account name')
    .min(1)
    .matches(nameRegExp, 'Invalid account name'),
  documentTypes: yup
    .array()
    .of(yup.string())
    .min(1)
    .required('Select at least one document type'),
})
