import { DocumentTypes } from '../controllers/partnership/types'

export type OriginatorEntity = {
  organization: string
  id: string
  documentTypes: DocumentTypes
  enabled: true
  dateCreated: number
  dateModified: number
}

export function originatorLabelByGuid(
  originators: Array<OriginatorEntity>,
  guid: string
): string {
  const found = originators.find((e) => e.id === guid)
  if (found) {
    return found.organization
  } else {
    return '<missing-org>'
  }
}
