import { Scopes, UserFields } from '../controllers/partnership/types'

export class ApplyProofUser {
  public readonly username: string
  public readonly email: string
  public readonly phone?: string
  public readonly firstName: string
  public readonly lastName: string
  public readonly accountId: string
  public readonly enabled: boolean
  public readonly confirmationStatus: string
  public readonly scopes: Scopes

  constructor(
    {
      username,
      confirmationStatus,
      email,
      enabled,
      accountId,
      phone,
      firstName,
      lastName,
    }: UserFields,
    scopes?: Scopes
  ) {
    this.username = username ?? '<n/a>'
    this.confirmationStatus = confirmationStatus ?? '<n/a>'
    this.email = email ?? '<n/a>'
    this.enabled = enabled ?? false
    this.accountId = accountId ?? ''
    this.phone = phone ?? ''
    this.firstName = firstName ?? ''
    this.lastName = lastName ?? ''
    this.scopes = scopes ?? []
  }

  get initials(): string {
    return this.email[0] + this.email[1]
  }

  json(): string {
    return JSON.stringify(this, null, 2)
  }
}

export type ApplyProofUserList = Array<ApplyProofUser>
