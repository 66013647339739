import * as React from 'react'

import ConfirmationDialog from '../../dialog/confirmation'

type EnableAccountDialogProps = {
  loading?: boolean
  username: string
  open?: boolean
  onCancel: () => void
  onAccept: () => void
}

export default function EnableAccountDialog({
  loading,
  username,
  open,
  onCancel,
  onAccept,
}: EnableAccountDialogProps): JSX.Element {
  const title = 'Enable account?'
  const text = `Enable account ${username}?`
  return (
    <ConfirmationDialog
      style="green"
      loading={loading}
      onAccept={onAccept}
      onCancel={onCancel}
      open={open}
      title={title}
      text={text}
    />
  )
}
