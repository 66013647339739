import {
  ArrowBack as ArrowBackIcon,
  Edit as EditIcon,
} from '@mui/icons-material'
import {
  Container,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import AppAlert, { AlertState } from '../components/app-alert'
import { Nav } from '../components/nav'
import DisableOriginatorDialog from '../components/originator/disable-originator'
import EditOriginatorDialog, {
  EditOriginatorDialogFields,
} from '../components/originator/edit-originator'
import EnableOriginatorDialog from '../components/originator/enable-originator'
// import { EditOriginatorDialogFields } from '../components/originator/edit-originator'
import OriginatorAvatar from '../components/originator/originator-avatar'
import OriginatorDetails from '../components/originator/originator-details'
import ToggleOriginatorStatusButton from '../components/originator/toggle-originator-status-button'
import { createOriginatorClient } from '../controllers/partnership'
import useOriginator from '../data/OriginatorData'
import useOriginators from '../data/OriginatorsData'

type OriginatorPageParams = {
  guid: string
}

export default function RootOriginatorPage(): JSX.Element {
  const history = useHistory()
  const { guid } = useParams<OriginatorPageParams>()
  const [editOpen, setEditOpen] = useState(false)
  const [editLoading, setEditLoading] = useState(false)
  const [disableOpen, setDisableOpen] = useState(false)
  const [disableLoading, setDisableLoading] = useState(false)
  const [enableOpen, setEnableOpen] = useState(false)
  const [enableLoading, setEnableLoading] = useState(false)

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    type: 'success',
    message: '',
  })

  const client = createOriginatorClient()

  const handleEditOriginator = () => {
    setEditOpen(true)
  }

  // const handleEditAccept = async (
  //   fields: EditOriginatorDialogFields
  // ): Promise<void> => {
  //   try {
  //     setEditLoading(true)
  //     if(fields.name !== ){

  //     }
  //     const response = await client.editOriginatorName({
  //       guid,
  //       newOrganizationName: fields.name,
  //     })
  //     // TODO when creating an account, need to take new account id when assigning documentTypes
  //     const documentTypeResponse = await client.assignDocumentTypes(fields.documentTypes)
  //     if (response.status === 200 && documentTypeResponse.status === 200) {
  //       mutateOriginator()
  //       setAlertState({
  //         open: true,
  //         type: 'success',
  //         message: 'Successfully updated account',
  //       })
  //     } else if (response.status !== 200) {
  //       mutateOriginator()
  //       setAlertState({
  //         open: true,
  //         type: 'error',
  //         message: 'Failure to update account name.',
  //       })
  //     } else {
  //       mutateOriginator()
  //       setAlertState({
  //         open: true,
  //         type: 'error',
  //         message: 'Failure to update verifiable document types.',
  //       })
  //     }
  //     mutateOriginators()
  //   } catch (err) {
  //     if (err instanceof Error) {
  //       console.error(err.stack)
  //     } else {
  //       console.error(err)
  //     }
  //     setAlertState({
  //       open: true,
  //       type: 'error',
  //       message: 'Failure to udpate account',
  //     })
  //   } finally {
  //     setEditLoading(false)
  //     setEditOpen(false)
  //   }
  // }

  const { mutateOriginators } = useOriginators('')
  const { data, isLoading, isError, mutateOriginator } = useOriginator(guid)

  let content
  if (isError) {
    content = (
      <Typography variant="h2" component="h2">
        Account not found
      </Typography>
    )
  } else if (data?.data) {
    const originator = data.data

    const handleEditAccept = async (
      fields: EditOriginatorDialogFields
    ): Promise<void> => {
      try {
        setEditLoading(true)
        if (fields.name !== originator.organization) {
          const response = await client.editOriginatorName({
            guid,
            newOrganizationName: fields.name,
          })
          if (response.status !== 200) {
            mutateOriginator()
            setAlertState({
              open: true,
              type: 'error',
              message: 'Failure to update account name.',
            })
          }
          return
        }
        const documentTypeResponse = await client.assignDocumentTypes(
          fields.documentTypes,
          originator.id
        )
        if (documentTypeResponse.status === 200) {
          mutateOriginator()
          setAlertState({
            open: true,
            type: 'success',
            message: 'Successfully updated account',
          })
        } else {
          mutateOriginator()
          setAlertState({
            open: true,
            type: 'error',
            message: 'Failure to update verifiable document types.',
          })
        }
        mutateOriginators()
      } catch (err) {
        if (err instanceof Error) {
          console.error(err.stack)
        } else {
          console.error(err)
        }
        setAlertState({
          open: true,
          type: 'error',
          message: 'Failure to update account',
        })
      } finally {
        setEditLoading(false)
        setEditOpen(false)
      }
    }

    const handleDisableOriginator = async () => {
      setDisableLoading(true)
      try {
        const response = await client.disableOriginator({
          guid,
        })
        if (response.status === 200) {
          mutateOriginator()
          setAlertState({
            open: true,
            type: 'success',
            message: 'Successfully disabled account',
          })
        } else {
          mutateOriginator()
          setAlertState({
            open: true,
            type: 'error',
            message: 'Failure to disable account.',
          })
        }
        mutateOriginators()
      } catch (err) {
        if (err instanceof Error) {
          console.error(err.stack)
        } else {
          console.error(err)
        }
        setAlertState({
          open: true,
          type: 'error',
          message: 'Failure to disable account',
        })
      } finally {
        setDisableLoading(false)
        setDisableOpen(false)
      }
    }

    const handleEnableOriginator = async () => {
      setEnableLoading(true)
      try {
        const response = await client.enableOriginator({
          guid: originator.id,
        })
        if (response.status === 200) {
          mutateOriginator()
        } else {
          mutateOriginator()
        }
        mutateOriginators()
        setAlertState({
          open: true,
          type: 'success',
          message: 'Successfully enabled account',
        })
      } catch (err) {
        if (err instanceof Error) {
          console.error(err.stack)
        } else {
          console.error(err)
        }
        setAlertState({
          open: true,
          type: 'error',
          message: 'Failure to enable account',
        })
      } finally {
        setEnableLoading(false)
        setEnableOpen(false)
      }
    }

    content = (
      <>
        <DisableOriginatorDialog
          loading={disableLoading}
          originator={originator}
          open={disableOpen}
          onCancel={() => setDisableOpen(false)}
          onAccept={handleDisableOriginator}
        />
        <EnableOriginatorDialog
          loading={enableLoading}
          originator={originator}
          open={enableOpen}
          onCancel={() => setEnableOpen(false)}
          onAccept={handleEnableOriginator}
        />
        <EditOriginatorDialog
          loading={editLoading}
          open={editOpen}
          originator={originator}
          originatorDocumentTypes={data?.documentTypes}
          onCancel={() => setEditOpen(false)}
          onAccept={handleEditAccept}
        />
        <Grid container spacing={3}>
          <Grid container spacing={2} alignItems="center" alignContent="center">
            <Grid item style={{ paddingLeft: 24 }}>
              <OriginatorAvatar originator={originator} />
            </Grid>
            <Grid item>
              <Typography variant="h1" component="h1" style={{ marginTop: 16 }}>
                {originator?.organization}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <Tooltip title="Edit Account">
                  <IconButton aria-label="edit" onClick={handleEditOriginator}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Paper style={{ padding: '3rem' }}>
              <Typography variant="h5" component="h5">
                Originator Details
              </Typography>
              <OriginatorDetails originator={originator} />
              <Grid container spacing={1} style={{ marginTop: 32 }}>
                <Grid item>
                  <ToggleOriginatorStatusButton
                    originator={originator}
                    onDisable={() => setDisableOpen(true)}
                    onEnable={() => setEnableOpen(true)}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    )
  } else {
    content = <></>
  }

  const handleAppalertClose = () => {
    setAlertState({ ...alertState, open: false })
  }

  return (
    <Container maxWidth="lg">
      <AppAlert {...alertState} onClose={handleAppalertClose} />
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingTop: 48 }}>
          <Nav loading={isLoading} />
        </Grid>
        <Grid item xs={12}>
          <IconButton
            onClick={() => history.goBack()}
            style={{ marginBottom: 40 }}
          >
            <ArrowBackIcon />
          </IconButton>
          {content}
        </Grid>
      </Grid>
    </Container>
  )
  // return (<></>)
}
