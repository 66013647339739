import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@mui/icons-material'
import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { red } from '@mui/material/colors'

import { ApplyProofUser } from '../../../applyproof'
import AccountAvatar from '../../account/account-avatar'
import AccountDetails from '../../account/account-details'
import ToggleAccountStatusButton from '../../account/toggle-account-status-button'
import AccountPermissions from '../account-permissions'
import React from 'react'

type UserInfoCardProps = {
  user: ApplyProofUser
  editUser: () => void
  openDeleteDialog: () => void
  openDisableDialog: () => void
  openEnableDialog: () => void
  trustHubChecked: boolean
  originatorHubChecked: boolean
  originatorHubPaidChecked: boolean
  dgsChecked: boolean
}
export default function UserInfoCard({
  user,
  editUser,
  openDeleteDialog,
  openDisableDialog,
  openEnableDialog,
  trustHubChecked,
  originatorHubChecked,
  originatorHubPaidChecked,
  dgsChecked,
}: UserInfoCardProps): JSX.Element {
  return (
    <>
      <Grid container spacing={3}>
        <Grid container spacing={2} alignItems="center" alignContent="center">
          <Grid item style={{ paddingLeft: 24 }}>
            <AccountAvatar user={user} />
          </Grid>
          <Grid item>
            <Typography variant="h1" component="h1" style={{ marginTop: 16 }}>
              {user?.email}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip title="Edit User">
                <IconButton aria-label="edit" onClick={editUser}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* <Grid item>
              <SendEmailButton user={user} />
            </Grid> */}
            <Grid item>
              {user.confirmationStatus === 'FORCE_CHANGE_PASSWORD' ? (
                <Tooltip title="User has not changed password.">
                  <IconButton
                    style={{ color: red[500] }}
                    edge="end"
                    aria-label="edit"
                  >
                    <ErrorOutlineIcon />
                  </IconButton>
                </Tooltip>
              ) : undefined}
            </Grid>
            <Grid item>
              {!user.enabled ? (
                <Tooltip title="Delete account? This is permanent and cannot be reversed.">
                  <IconButton
                    style={{ color: red[900] }}
                    edge="end"
                    aria-label="delete"
                    onClick={openDeleteDialog}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : undefined}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: '3rem' }}>
            <Typography variant="h5" component="h5">
              Account Details
            </Typography>
            <AccountDetails user={user} />
            <>
              <Typography
                variant="h5"
                component="h5"
                style={{ paddingTop: '2rem' }}
              >
                Account Permissions
              </Typography>
              <AccountPermissions
                user={user}
                trustHubPermission={trustHubChecked}
                originatorHubPermission={originatorHubChecked}
                originatorHubPaidChecked={originatorHubPaidChecked}
                dgsPermission={dgsChecked}
              />
            </>
            <Grid container spacing={1} style={{ marginTop: 32 }}>
              <Grid item style={{ flex: 2 }}></Grid>
              <Grid item>
                <ToggleAccountStatusButton
                  user={user}
                  onDisable={openDisableDialog}
                  onEnable={openEnableDialog}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
