import { styled, Container, Grid } from '@mui/material'

export const RootContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    paddingTop: '20vh',
  },
  [theme.breakpoints.down('xs')]: {
    paddingTop: 0,
    padding: '3rem',
  },
}))

export const LoginFormGridItem = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    minWidth: 530,
  },
  [theme.breakpoints.down('xs')]: {
    margin: '3rem',
  },
}))
