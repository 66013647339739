import { AxiosResponse } from 'axios'
import { useState } from 'react'
import useSWR from 'swr'
import { mutateCallback } from 'swr/dist/types'

import {
  createPartnershipClient,
  partnershipKeys,
} from '../controllers/partnership'
import { ListUsersResponse } from '../controllers/partnership/types'

type UseUsersType = {
  data: AxiosResponse<ListUsersResponse> | undefined
  isLoading: boolean
  isError: boolean
  mutateUsers: (
    data?:
      | AxiosResponse<ListUsersResponse>
      | Promise<AxiosResponse<ListUsersResponse>>
      | mutateCallback<AxiosResponse<ListUsersResponse>>,
    shouldRevalidate?: boolean
  ) => Promise<AxiosResponse<ListUsersResponse> | undefined>
}
export default function useUsers(
  pageNumber: number,
  filter?: string | undefined,
  accountFilter?: string | undefined
): UseUsersType {
  const [tokens, setTokens] = useState<Array<string | undefined>>([])
  const client = createPartnershipClient()
  const key = partnershipKeys.listUsers(
    pageNumber,
    filter ?? '',
    accountFilter ?? ''
  )
  const fetcher = async () => {
    /** AccountId field does not allow pagination */
    const params = accountFilter
      ? { filter, accountId: accountFilter }
      : {
          filter,
          paginationToken:
            pageNumber === 1 ? undefined : tokens[pageNumber - 2],
        }
    const response = await client.listUsers(params)
    /* when we visit a new page, we add the latest token to the token array */
    if (tokens.length < pageNumber && response.data.paginationToken) {
      setTokens((tokens) => [...tokens, response.data.paginationToken])
      /* when we visit a previously visited page, we update the corresponding token in the token array */
    } else if (tokens.length >= pageNumber && response.data.paginationToken) {
      setTokens((tokens) =>
        tokens.map((token, index) =>
          index === pageNumber - 1 ? response.data.paginationToken : token
        )
      )
    }
    return response
  }

  const { data, mutate } = useSWR(key, fetcher, {
    revalidateOnMount: true,
  })

  const loading = !data
  const error = !loading && data?.status !== 200

  return {
    data: data,
    isLoading: loading,
    isError: error,
    mutateUsers: mutate,
  }
}
