import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material'
import { Box, Tooltip, Button, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { OriginatorEntity } from '../../applyproof'
import { createOriginatorClient } from '../../controllers/partnership'
import { DocumentTypes } from '../../controllers/partnership/types'
import AppAlert, { AlertState } from '../app-alert'
import EditOriginatorDialog from '../originator/edit-originator'
import ShowEnabledDisabled from '../show-enabled-disabled'

const classes = {
  root: {
    display: 'flex',
  },
}

export type OriginatorActionBarProps = {
  onOriginatorCreated?: (user: OriginatorEntity) => void
  onNameFilter?: (filter: string) => void
  setShowEnabled?: (showEnabled: boolean) => void
  setShowDisabled?: (showDisabled: boolean) => void
}

export default function OriginatorActionBar({
  onOriginatorCreated = () => void 0,
  onNameFilter = () => void 0,
  setShowEnabled = () => void 0,
  setShowDisabled = () => void 0,
}: OriginatorActionBarProps): JSX.Element {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    type: 'success',
    message: '',
  })

  const handleCreateCancel = () => {
    setCreateOpen(false)
  }

  const client = createOriginatorClient()
  const handleCreateAccept = async (fields: {
    name: string
    documentTypes: DocumentTypes
  }): Promise<void> => {
    try {
      setLoading(true)
      const response = await client.createOriginator({
        organization: fields.name,
      })

      if (response.status === 200) {
        const originator = response.data
        const documentTypeResponse = await client.assignDocumentTypes(
          fields.documentTypes,
          originator.id
        )
        if (documentTypeResponse.status === 200) {
          onOriginatorCreated(originator)
          setAlertState({
            open: true,
            type: 'success',
            message: 'Successfully created account',
          })
          history.push(`/originators/${originator.id ?? ''}`)
        } else {
          onOriginatorCreated(originator)
          setAlertState({
            open: true,
            type: 'error',
            message: 'Created account but unable to set document types.',
          })
          history.push(`/originators/${originator.id ?? ''}`)
        }
      } else {
        setAlertState({
          open: true,
          type: 'error',
          message: 'Failure to create account. Do they already exist?',
        })
        console.error('failure to create account')
      }
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.stack)
      } else {
        console.error(err)
      }
      setAlertState({
        open: true,
        type: 'error',
        message: 'Failure to create account',
      })
    } finally {
      setLoading(false)
      setCreateOpen(false)
    }
  }

  const handleAlertClose = () => {
    setAlertState({
      open: false,
      type: alertState.type,
      message: alertState.message,
    })
  }

  const [nameFilter, setNameFilter] = useState('')

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(e.target.value)
    onNameFilter(e.target.value)
  }

  const [showEnabled, setShowEnabledLocal] = useState(true)
  const [showDisabled, setShowDisabledLocal] = useState(true)

  const handleShowEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowEnabled(e.target.checked) // update in parent element
    setShowEnabledLocal(e.target.checked)
  }

  const handleShowDisabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDisabled(e.target.checked)
    setShowDisabledLocal(e.target.checked)
  }

  return (
    <Box sx={classes.root}>
      <AppAlert {...alertState} onClose={handleAlertClose} />
      <EditOriginatorDialog
        loading={loading}
        open={createOpen}
        onCancel={handleCreateCancel}
        onAccept={handleCreateAccept}
      />
      <Grid container spacing={3} alignContent="center" alignItems="center">
        <Grid item>
          <TextField
            label="Search..."
            value={nameFilter}
            onChange={handleFilterChange}
          ></TextField>
        </Grid>
        <Grid item>
          <ShowEnabledDisabled
            showDisabled={showDisabled}
            showEnabled={showEnabled}
            handleShowDisabledChange={handleShowDisabledChange}
            handleShowEnabledChange={handleShowEnabledChange}
          />
        </Grid>
        <Grid item>
          <Tooltip title="Create new account">
            <Button
              startIcon={<AddCircleOutlineIcon />}
              variant="text"
              onClick={() => setCreateOpen(true)}
            >
              Create
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  )
}
