import * as React from 'react'

import { OriginatorEntity } from '../../../applyproof'
import ConfirmationDialog from '../../dialog/confirmation'

type DisableOriginatorDialogProps = {
  loading?: boolean
  originator: OriginatorEntity
  open?: boolean
  onCancel: () => void
  onAccept: () => void
}

export default function DisableOriginatorDialog({
  loading,
  originator,
  open,
  onCancel,
  onAccept,
}: DisableOriginatorDialogProps): JSX.Element {
  const title = 'Disable account?'
  const text = `Disable account ${originator.organization}?`
  return (
    <ConfirmationDialog
      style="hazardous"
      loading={loading}
      onAccept={onAccept}
      onCancel={onCancel}
      open={open}
      title={title}
      text={text}
    />
  )
}
