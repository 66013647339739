import { Typography } from '@mui/material'
import React from 'react'

import { OriginatorEntity } from '../../../applyproof/originators'
import { colors } from '../../../theme/color'

export type OriginatorDetailsProps = {
  originator: OriginatorEntity
}

export default function OriginatorDetails({
  originator,
}: OriginatorDetailsProps): JSX.Element {
  return (
    <>
      {originator.enabled ? (
        <Typography
          variant="body2"
          style={{ color: colors.accent.green.light }}
          component="p"
        >
          Enabled
        </Typography>
      ) : (
        <Typography
          variant="body2"
          style={{ color: colors.accent.red.light }}
          component="p"
        >
          Disabled
        </Typography>
      )}
      <Typography variant="body2" color="textSecondary" component="p">
        Name: {originator.organization}
      </Typography>
      {/* <Typography variant="body2" color="textSecondary" component="p">
        OrgId: {originator.id}
      </Typography> */}
      <Typography variant="body2" color="textSecondary" component="p">
        Created {new Date(originator.dateCreated).toLocaleString()}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        Last Modified {new Date(originator.dateModified).toLocaleString()}
      </Typography>
    </>
  )
}
