import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useContext, useEffect } from 'react'

import { ApplyProofUser } from '../../../applyproof/user'
import {
  hasDgsScopes,
  hasOriginatorHubPaidScopes,
  hasOriginatorHubScopes,
  hasTrustHubScopes,
} from '../../../utils/scopes'
import { OriginatorContext } from '../../originator-context'
import { validationSchema } from './validate'

export type EditAccountDialogFields = {
  username: string
  email: string
  phone: string | undefined
  firstName: string
  lastName: string
  accountId: string
  trustHub: boolean
  originatorHub: boolean
  originatorHubPaid: boolean
  dgs: boolean
}

export type EditAccountDialogProps = {
  loading?: boolean
  user?: ApplyProofUser | undefined
  open?: boolean
  onCancel: () => void
  onAccept: (fields: EditAccountDialogFields) => Promise<void>
}

export default function EditAccountDialog({
  loading,
  user,
  open,
  onCancel,
  onAccept,
}: EditAccountDialogProps): JSX.Element {
  const initialValues: EditAccountDialogFields = {
    username: user?.username ?? '',
    email: user?.email ?? '',
    phone: user?.phone ?? '',
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    accountId: user?.accountId ?? '',
    trustHub: user?.scopes ? hasTrustHubScopes(user.scopes) : false,
    originatorHub: user?.scopes ? hasOriginatorHubScopes(user.scopes) : false,
    originatorHubPaid: user?.scopes
      ? hasOriginatorHubPaidScopes(user.scopes)
      : false,
    dgs: user?.scopes ? hasDgsScopes(user.scopes) : false,
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onAccept,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  })

  useEffect(() => {
    formik.resetForm({
      values: {
        username: user?.username ?? '',
        email: user?.email ?? '',
        phone: user?.phone ?? '',
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        accountId: user?.accountId ?? '',
        trustHub: user?.scopes ? hasTrustHubScopes(user.scopes) : false,
        originatorHub: user?.scopes
          ? hasOriginatorHubScopes(user.scopes)
          : false,
        originatorHubPaid: user?.scopes
          ? hasOriginatorHubPaidScopes(user.scopes)
          : false,
        dgs: user?.scopes ? hasDgsScopes(user.scopes) : false,
      },
    })
  }, [open])

  const action = user ? 'Edit' : 'Create'

  const originators = useContext(OriginatorContext)

  return (
    <Dialog
      open={Boolean(open)}
      onClose={onCancel}
      aria-labelledby="edit-account-dialog"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="edit-account-dialog">{action} User</DialogTitle>
        <DialogContent style={{ minHeight: '400px' }}>
          <DialogContentText>
            <Typography component="span" variant="body1">
              {action} user{' '}
              <Typography component="span" variant="h5">
                {user ? user.email : ''}
              </Typography>
            </Typography>
          </DialogContentText>
          <FormControl
            required
            fullWidth
            error={Boolean(formik.errors.accountId)}
            style={{ marginTop: 42 }}
            component="fieldset"
          >
            <Autocomplete
              defaultValue={originators.find((e) => e.id === user?.accountId)}
              options={originators}
              getOptionLabel={(e) => e.organization}
              fullWidth
              onChange={(e, value) => {
                formik.setFieldValue('accountId', value?.id ?? '')
              }}
              onOpen={() => formik.handleBlur('accountId')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(formik.errors.accountId)}
                  name="accountId"
                  id="accountId"
                  label="AccountId"
                />
              )}
            />
            <FormHelperText>{formik.errors.accountId || ' '}</FormHelperText>
          </FormControl>
          {user ? (
            <FormControl
              required
              fullWidth
              error={Boolean(formik.errors.username)}
              component="fieldset"
            >
              <TextField
                disabled
                id="username"
                name="username"
                type="text"
                label="Username"
                fullWidth
                error={Boolean(formik.errors.username)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                inputProps={{
                  maxLength: 128,
                }}
              />
              <FormHelperText>{formik.errors.username || ' '}</FormHelperText>
            </FormControl>
          ) : undefined}
          <FormControl
            required
            fullWidth
            error={Boolean(formik.errors.email)}
            component="fieldset"
          >
            <TextField
              style={{ minWidth: '48ch' }}
              disabled={Boolean(user)}
              id="email"
              name="email"
              type="text"
              label="Email"
              fullWidth
              error={Boolean(formik.errors.email)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              inputProps={{
                maxLength: 128,
              }}
            />
            <FormHelperText>{formik.errors.email || ' '}</FormHelperText>
          </FormControl>
          <FormControl
            fullWidth
            error={Boolean(formik.errors.phone)}
            component="fieldset"
          >
            <TextField
              style={{ minWidth: '48ch' }}
              // disabled={Boolean(user)}
              id="phone"
              name="phone"
              type="text"
              label="Phone"
              fullWidth
              error={Boolean(formik.errors.phone)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {user ? '' : '+'}
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText>{formik.errors.phone || ' '}</FormHelperText>
          </FormControl>
          <FormControl
            required
            fullWidth
            error={Boolean(formik.errors.firstName)}
            component="fieldset"
          >
            <TextField
              style={{ minWidth: '48ch' }}
              // disabled={Boolean(user)}
              id="firstName"
              name="firstName"
              type="text"
              label="FirstName"
              fullWidth
              error={Boolean(formik.errors.firstName)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              inputProps={{
                maxLength: 128,
              }}
            />
            <FormHelperText>{formik.errors.firstName || ' '}</FormHelperText>
          </FormControl>
          <FormControl
            required
            fullWidth
            error={Boolean(formik.errors.lastName)}
            component="fieldset"
          >
            <TextField
              style={{ minWidth: '48ch' }}
              // disabled={Boolean(user)}
              id="lastName"
              name="lastName"
              type="text"
              label="LastName"
              fullWidth
              error={Boolean(formik.errors.lastName)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              inputProps={{
                maxLength: 128,
              }}
            />
            <FormHelperText>{formik.errors.lastName || ' '}</FormHelperText>
          </FormControl>
          <>
            <Grid container direction="row" style={{ paddingLeft: '1ch' }}>
              <Grid item xs={4}>
                <FormControl
                  required
                  fullWidth
                  error={Boolean(formik.errors.trustHub)}
                  component="fieldset"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.trustHub}
                        onChange={formik.handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        // style={{ marginBottom: '0.5rem' }}
                        name={'trustHub'}
                      />
                    }
                    label={'TrustHub'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  required
                  fullWidth
                  error={Boolean(formik.errors.originatorHub)}
                  component="fieldset"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.originatorHub}
                        onChange={formik.handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        // style={{ marginBottom: '0.5rem' }}
                        name={'originatorHub'}
                      />
                    }
                    label={'OriginatorHub'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  required
                  fullWidth
                  error={Boolean(formik.errors.originatorHubPaid)}
                  component="fieldset"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.originatorHubPaid}
                        onChange={formik.handleChange}
                        inputProps={{ 'aria-label': 'enable-ircc-report' }}
                        data-cy="ircc-report-enable"
                        name={'originatorHubPaid'}
                      />
                    }
                    label={'IRCC Report Generator'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  required
                  fullWidth
                  error={Boolean(formik.errors.dgs)}
                  component="fieldset"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.dgs}
                        onChange={formik.handleChange}
                        inputProps={{ 'aria-label': 'enable-dgs' }}
                        data-cy="dgs-enable"
                        name={'dgs'}
                      />
                    }
                    label={'Document Generator Service'}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button
            type="reset"
            onClick={onCancel}
            variant="text"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            endIcon={loading ? <CircularProgress size={20} /> : undefined}
            disabled={loading}
          >
            {action}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
