import { Edit as EditIcon } from '@mui/icons-material'
import {
  Card,
  CardContent,
  Tooltip,
  Typography,
  List,
  Skeleton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  createTheme,
} from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { OriginatorEntity } from '../../applyproof/originators'
import { colors } from '../../theme/color'
import OriginatorAvatar from '../originator/originator-avatar'

const theme = createTheme()
const classes = {
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    width: '100%',
    height: '4rem',
  },
}

export type OriginatorListProps = {
  loading: boolean
  originators: Array<OriginatorEntity>
  onSelectOriginator?: (user: OriginatorEntity | undefined) => void | undefined
}

export default function OriginatorList({
  loading,
  originators,
  onSelectOriginator,
}: OriginatorListProps): JSX.Element {
  const [selectedOriginator, setSelectedOriginator] = useState<
    OriginatorEntity | undefined
  >()

  const originatorsRef = useRef<Array<OriginatorEntity> | undefined>(undefined)

  originatorsRef.current = originators

  useEffect(() => {
    if (onSelectOriginator) {
      onSelectOriginator(selectedOriginator)
    }
  }, [selectedOriginator])

  useEffect(() => {
    // selectRelativeUser(0)
  }, [originators])

  const selectRelativeOriginator = (offset: number): void => {
    const originators = originatorsRef.current
    if (originators) {
      setSelectedOriginator((selectedOriginator) => {
        if (selectedOriginator) {
          const existingIndex =
            originators.findIndex(
              (originator) => originator.id === selectedOriginator.id
            ) ?? 0
          const nextIndex = Math.max(
            0,
            Math.min(existingIndex + offset, originators.length - 1)
          )
          return originators[nextIndex]
        } else {
          return originators[offset >= 0 ? 0 : originators.length - 1]
        }
      })
    } else {
      return setSelectedOriginator(undefined)
    }
  }

  useHotkeys('k', () => selectRelativeOriginator(-1))
  useHotkeys('j', () => selectRelativeOriginator(1))

  const originatorList =
    originators &&
    originators.length > 0 &&
    originators.map((originator: OriginatorEntity, i: number) => {
      const name =
        originator.organization ?? `<No Name> - (${originator.organization})`
      const labelId = `checkbox-list-label-${originator.organization}`

      const selected = originator.id === selectedOriginator?.id

      return (
        <ListItem
          sx={classes.item}
          key={i}
          role={undefined}
          button
          style={
            selected ? { backgroundColor: colors.secondary.A500 } : undefined
          }
          onClick={() => setSelectedOriginator(originator)}
        >
          <ListItemIcon>
            <OriginatorAvatar originator={originator} />
          </ListItemIcon>
          <ListItemText
            id={labelId}
            primary={
              <Typography
                style={{ userSelect: 'text' }}
                component="span"
                variant="body1"
              >
                {name}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              style={{ marginLeft: 12 }}
              edge="end"
              aria-label="edit"
              href={`/originators/${originator.id}`}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )
    })

  return (
    <>
      <List sx={classes.root}>
        {loading ? (
          [1, 2, 3, 4, 5].map((i) => (
            <Skeleton
              key={i}
              variant="rectangular"
              animation="wave"
              style={{ height: '3rem', marginBottom: '0.5rem' }}
            />
          ))
        ) : originators.length > 0 ? (
          originatorList
        ) : (
          <Card>
            <CardContent>No Originators found</CardContent>
          </Card>
        )}
      </List>
    </>
  )
}
