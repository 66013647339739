import { Link } from '@mui/material'
import React from 'react'

export type PleaseContactSupportProps = {
  subject?: string | undefined
}

export default function PleaseContactSupport({
  subject,
}: PleaseContactSupportProps): JSX.Element {
  return subject ? (
    <Link href="mailto:applyproof.com">
      support@applyproof.com?subject={encodeURIComponent(subject)}
    </Link>
  ) : (
    <Link href="mailto:applyproof.com">support@applyproof.com</Link>
  )
}
