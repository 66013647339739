import React, { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import RootLoginPage from '../../pages/Login'
import RootOriginatorPage from '../../pages/Originator'
import RootOriginatorsPage from '../../pages/Originators'
import RootUserPage from '../../pages/User'
import RootUsersPage from '../../pages/Users'
import AuthProvider from '../auth-provider'
import AuthRoute from '../auth-route'
import KeyboardShortcuts from '../keyboard-shortcuts'
import OriginatorProvider from '../originator-context'

export default function MyApp(): JSX.Element {
  const [keyboardShortcutsOpen, setKeyboardShortcutsOpen] = useState(false)

  useHotkeys('shift+/', () =>
    setKeyboardShortcutsOpen((keyboardShortcutsOpen) => !keyboardShortcutsOpen)
  )

  return (
    <AuthProvider>
      <>
        <KeyboardShortcuts
          open={keyboardShortcutsOpen}
          onClose={() => setKeyboardShortcutsOpen(false)}
        />
        <Router>
          <Switch>
            <Route path="/originators/:guid">
              <AuthRoute>
                <OriginatorProvider>
                  <RootOriginatorPage />
                </OriginatorProvider>
              </AuthRoute>
            </Route>
            <Route path="/originators">
              <AuthRoute>
                <OriginatorProvider>
                  <RootOriginatorsPage />
                </OriginatorProvider>
              </AuthRoute>
            </Route>
            <Route path="/users/:userId">
              <AuthRoute>
                <OriginatorProvider>
                  <RootUserPage />
                </OriginatorProvider>
              </AuthRoute>
            </Route>
            <Route path="/users">
              <AuthRoute>
                <OriginatorProvider>
                  <RootUsersPage />
                </OriginatorProvider>
              </AuthRoute>
            </Route>
            <Route path="/">
              <RootLoginPage />
            </Route>
          </Switch>
        </Router>
      </>
    </AuthProvider>
  )
}
