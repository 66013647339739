import useSWR from 'swr'

import {
  createPartnershipClient,
  partnershipKeys,
} from '../controllers/partnership'

export default function useUser(userId: string) {
  const client = createPartnershipClient()
  const userKey = partnershipKeys.getUser(userId)
  const userData = useSWR(userKey, () => client.getUser(userId), {
    revalidateOnMount: true,
  })

  const scopesKey = partnershipKeys.getScopes(userId)
  const userScopesData = useSWR(scopesKey, () => client.getUserScopes(userId), {
    revalidateOnMount: true,
  })

  const loading = !userData.data && !userScopesData.data
  const error =
    !loading &&
    userData.data?.status !== 200 &&
    userScopesData.data?.status !== 200

  return {
    userData: userData.data,
    userScopesData: userScopesData.data,
    isLoading: loading,
    isError: error,
    mutateUser: userData.mutate,
    mutateScopes: userScopesData.mutate,
  }
}
