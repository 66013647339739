import { OriginatorEntity } from '../../applyproof'

// user

export type ListUsersParams = {
  accountId?: string
  paginationToken?: string
  filter?: string
}
export type ListUsersResponse = {
  users: Array<UserFields>
  paginationToken?: string
}

export type UserFields = {
  username: string
  confirmationStatus: string
  email: string
  enabled: boolean
  accountId: string
  firstName: string
  lastName: string
  phone: string
}

export type GetUserResponse = UserFields

export const DgsScopes = ['dgs:write:document']

export const TrustHubScopes = [
  'trusthub-ext:read:document',
  'trusthub-ext:read:language',
  'trusthub-ext:read:ap',
]

export const OriginatorHubScopes = [
  'originatorhub:read:document',
  'originatorhub:invalidate:document',
  'originatorhub:write:document',
  'originatorhub:write:action',
]

export const OriginatorHubPaidScopes = ['originatorhub:execute:report']

export const VALID_SCOPES = [
  ...TrustHubScopes,
  ...OriginatorHubScopes,
  ...OriginatorHubPaidScopes,
  ...DgsScopes,
] as const
export type Scope = typeof VALID_SCOPES[number]
export type Scopes = Array<Scope>

export type CreateUserParams = {
  email: string
  phone?: string
  firstName: string
  lastName: string
  accountId: string
  scopes: Scopes
}

export type EditUserParams = {
  email: string
  phone?: string
  firstName: string
  lastName: string
  accountId: string
}

export type ConfirmationStatus =
  | 'FORCE_CHANGE_PASSWORD'
  | 'RESET_REQUIRED'
  | 'UNKNOWN'
  | 'COMPROMISED'
  | 'ARCHIVED'
  | 'UNCONFIRMED'
  | 'CONFIRMED'
  | string

export type CreateUserResponse = {
  username: string
  email: string
  phone: string
  firstName: string
  lastName: string
  accountId: string
  enabled: true
  confirmationStatus: ConfirmationStatus
}

export type UserResponse = {
  username: string
  email: string
  phone: string | null
  firstName: string
  lastName: string
  accountId: string
  enabled: true
  confirmationStatus: ConfirmationStatus | null
}

type ConfirmationResponse = {
  complete: true
}

export type ScopesResponse = {
  scopes: Scopes
  id: string
}

export type EnableUserResponse = UserResponse
export type DisableUserResponse = UserResponse

export type EditAccountResponse = UserResponse
export type EditNameResponse = UserResponse
export type EditPhoneResponse = UserResponse
export type EditEmailResponse = UserResponse

export type ResetPasswordResponse = ConfirmationResponse

export type ConfirmResetPasswordResponse = ConfirmationResponse

export type ListOriginatorsResponse = Array<OriginatorEntity>

export type CreateOriginatorResponse = OriginatorEntity

export type CreateOriginatorParams = {
  organization: string
}

export type GetOriginatorParams = {
  guid: string
}

export type ApDocumentType = 'POA' | 'POFS' | 'POLP' | 'POP'

export type DocumentType = ApDocumentType | string

export type DocumentTypes = Array<DocumentType>

export type AssignDocumentTypesReponse = {
  documentTypes: DocumentTypes
  pkey: string
}
