import Avatar from '@mui/material/Avatar'
import { green, red } from '@mui/material/colors'
import React from 'react'

import { ApplyProofUser } from '../../../applyproof'

export type AccountAvatarProps = {
  user?: ApplyProofUser | undefined
}

export default function AccountAvatar({
  user,
}: AccountAvatarProps): JSX.Element {
  const backgroundColor = user?.enabled ? green[500] : red[500]
  return (
    <Avatar
      aria-label="profile"
      alt={user?.email ?? ''}
      style={{ backgroundColor }}
    >
      {user?.initials ?? ''}
    </Avatar>
  )
}
