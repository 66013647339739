import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React, { useState, useEffect, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'

import { OriginatorEntity } from '../../applyproof'
import { createOriginatorClient } from '../../controllers/partnership'

export const OriginatorContext = React.createContext(
  [] as Array<OriginatorEntity>
)

interface Props {
  children: ReactNode
}

export const OriginatorProvider = (props: Props): JSX.Element => {
  const [originators, setOriginators] = useState([] as Array<OriginatorEntity>)
  const [showError, setShowError] = useState(false)
  const history = useHistory()

  const client = createOriginatorClient()

  const getOriginators = async () => {
    try {
      const response = await client.listOriginators()
      const originators =
        response.status === 200
          ? response.data
          : ([] as Array<OriginatorEntity>)
      setOriginators(originators)
    } catch (err) {
      console.debug(err)
      setShowError(true)
    }
  }
  useEffect(() => {
    getOriginators()
  }, [])

  const onClose = () => {
    setShowError(false)
    history.push('/')
  }

  return (
    <OriginatorContext.Provider value={originators}>
      {props.children}
      <Dialog
        open={showError ?? false}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Session expired.</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your login session has expired. Please return to the login page to
            sign in again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="text">
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </OriginatorContext.Provider>
  )
}

export default OriginatorProvider
