import * as yup from 'yup'

// The following regex is more versatile for phone numbers, but for now we'll use the same one as the admin api
// const phoneRegExp = /^\+?((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const phoneRegExp = new RegExp('\\+?(\\d)+')
const firstNameRegExp = new RegExp('.*[^\\s].*')
const lastNameRegExp = new RegExp('.*[^\\s].*')

export const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Enter user email'),
  username: yup.string().optional(),
  accountId: yup.string().required('Enter account name').min(1),
  firstName: yup
    .string()
    .required('Enter first name')
    .min(1)
    .matches(firstNameRegExp, 'Invalid first name'),
  lastName: yup
    .string()
    .required('Enter last name')
    .min(1)
    .matches(lastNameRegExp, 'Invalid last name'),
  phone: yup.string().matches(phoneRegExp).optional(),
  trustHub: yup.boolean(),
  originatorHub: yup.boolean(),
  dgs: yup.boolean(),
})
