import { Button } from '@mui/material'
import { green, red } from '@mui/material/colors'
import React from 'react'

import { ApplyProofUser } from '../../../applyproof/user'

export type ToggleAccountStatusButtonProps = {
  user: ApplyProofUser
  onDisable: () => void
  onEnable: () => void
}

export default function ToggleAccountStatusButton({
  user,
  onDisable,
  onEnable,
}: ToggleAccountStatusButtonProps): JSX.Element {
  const text = user.enabled ? 'Disable' : 'Enable'
  const callback = user.enabled ? onDisable : onEnable
  const color = user.enabled ? red[500] : green[500]
  return (
    <Button
      style={{ color: color, borderColor: color }}
      size="small"
      variant="outlined"
      onClick={callback}
    >
      {text}
    </Button>
  )
}
