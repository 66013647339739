import { Grid } from '@mui/material'

import { ApplyProofUser } from '../../../applyproof'

import PermissionsCheckbox from '../permissions-checkbox'
import React from 'react'

export type AccountPermissionsProps = {
  user: ApplyProofUser
  trustHubPermission: boolean
  originatorHubPermission: boolean
  originatorHubPaidChecked: boolean
  dgsPermission: boolean
}

export default function AccountPermissions({
  user,
  trustHubPermission,
  originatorHubPermission,
  originatorHubPaidChecked,
  dgsPermission,
}: AccountPermissionsProps): JSX.Element {
  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={4}>
          <PermissionsCheckbox
            title={'TrustHub'}
            checkboxState={trustHubPermission}
          />
        </Grid>
        <Grid item xs={6}>
          <PermissionsCheckbox
            title={'Originator Hub'}
            checkboxState={originatorHubPermission}
          />
        </Grid>
        <Grid item xs={12}>
          <PermissionsCheckbox
            title={'IRCC Report Generator'}
            data-cy="ircc-report-generator-enable"
            aria-label="ircc-report-enable"
            checkboxState={originatorHubPaidChecked}
          />
        </Grid>
        <Grid item xs={12}>
          <PermissionsCheckbox
            title={'Document Generator Service'}
            data-cy="document-generator-service-enable"
            aria-label="dgs-enable"
            checkboxState={dgsPermission}
          />
        </Grid>
      </Grid>
    </>
  )
}
