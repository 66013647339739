export class LocalCognitoStorage {
  setItem(key: string, value: string): void {
    window.localStorage.setItem(key, value)
  }

  setIdToken(newToken: string): void {
    this.setItem('idToken', newToken)
  }

  setRefreshToken(newToken: string): void {
    this.setItem('refreshToken', newToken)
  }

  getItem(key: string): string {
    const item = window.localStorage.getItem(key)
    if (item) {
      return item
    }
    throw new Error('Invalid key. The key does not exist.')
  }

  getIdToken(): string {
    try {
      const item = this.getItem('idToken')
      return item
    } catch (err) {
      throw new Error('No idToken found')
    }
  }

  getRefreshToken(): string {
    try {
      const item = this.getItem('refreshToken')
      return item
    } catch (err) {
      throw new Error('No refreshToken found')
    }
  }

  removeItem(key: string): void {
    window.localStorage.removeItem(key)
  }

  clear(): void {
    window.localStorage.clear()
  }
}
