import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { Container, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { ApplyProofUser } from '../applyproof/user'
import DeleteAccountDialog from '../components/account/delete-account'
import DisableAccountDialog from '../components/account/disable-account'
import EditAccountDialog, {
  EditAccountDialogFields,
} from '../components/account/edit-account'
import EnableAccountDialog from '../components/account/enable-account'
import AppAlert, { AlertState } from '../components/app-alert'
import { Nav } from '../components/nav'
import UserInfoCard from '../components/user/user-info-card'
import { createPartnershipClient } from '../controllers/partnership'
import { Scopes } from '../controllers/partnership/types'
import useUser from '../data/UserData'
import useUsers from '../data/UsersData'
import { formatPhoneNumber } from '../utils/formatPhone'
import {
  hasDgsScopes,
  hasOriginatorHubPaidScopes,
  hasOriginatorHubScopes,
  hasTrustHubScopes,
  setScopes,
} from '../utils/scopes'

type UserPageParams = {
  userId: string
}

export default function RootUserPage(): JSX.Element {
  const history = useHistory()
  const { userId } = useParams<UserPageParams>()
  const [editOpen, setEditOpen] = useState(false)
  const [editLoading, setEditLoading] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [disableOpen, setDisableOpen] = useState(false)
  const [disableLoading, setDisableLoading] = useState(false)
  const [enableOpen, setEnableOpen] = useState(false)
  const [enableLoading, setEnableLoading] = useState(false)
  const [trustHubChecked, setTrustHubChecked] = React.useState(false)
  const [originatorHubChecked, setOriginatorHubChecked] = React.useState(false)
  const [originatorHubPaidChecked, setOriginatorHubPaidChecked] =
    React.useState(false)
  const [dgsChecked, setDgsChecked] = React.useState(false)

  const setUserScopes = (scopes: Scopes) => {
    setOriginatorHubChecked(hasOriginatorHubScopes(scopes))
    setOriginatorHubPaidChecked(hasOriginatorHubPaidScopes(scopes))
    setTrustHubChecked(hasTrustHubScopes(scopes))
    setDgsChecked(hasDgsScopes(scopes))
  }

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    type: 'success',
    message: '',
  })

  const client = createPartnershipClient()

  const handleEditUser = () => {
    setEditOpen(true)
  }

  const { mutateUsers } = useUsers(1, '')
  const {
    userData,
    userScopesData,
    isLoading,
    isError,
    mutateUser,
    mutateScopes,
  } = useUser(userId)

  useEffect(() => {
    if (userScopesData?.data) {
      setUserScopes(userScopesData.data.scopes)
    }
  }, [userScopesData?.data])

  let content
  if (isError) {
    content = (
      <Typography variant="h2" component="h2">
        User not found
      </Typography>
    )
  } else if (userData?.data && userScopesData?.data) {
    const user = new ApplyProofUser(userData?.data, userScopesData?.data.scopes)
    const handleEditAccept = async (
      fields: EditAccountDialogFields
    ): Promise<void> => {
      try {
        setEditLoading(true)
        const userResponse = await client.editUser(fields.username, {
          email: fields.email,
          ...formatPhoneNumber(fields.phone),
          firstName: fields.firstName,
          lastName: fields.lastName,
          accountId: fields.accountId,
        })

        const scopesResponse = await client.editUserScopes(
          fields.username,
          setScopes({
            trustHub: fields.trustHub,
            originatorHub: fields.originatorHub,
            originatorHubPaid: fields.originatorHubPaid,
            dgs: fields.dgs,
          })
        )

        if (userResponse.status === 200 && scopesResponse.status == 200) {
          mutateUser()
          mutateScopes()
          setAlertState({
            open: true,
            type: 'success',
            message: 'Successfully updated user',
          })
        } else {
          mutateUser()
          mutateScopes()
          setAlertState({
            open: true,
            type: 'error',
            message: 'Unable to update user',
          })
        }
        mutateUsers()
      } catch (err) {
        if (err instanceof Error) {
          console.error(err.stack)
        } else {
          console.error(err)
        }
        setAlertState({
          open: true,
          type: 'error',
          message: 'Failure to edit user',
        })
      } finally {
        setEditLoading(false)
        setEditOpen(false)
      }
    }

    const handleDisableAccount = async () => {
      setDisableLoading(true)
      try {
        const response = await client.disableUser(user.username)
        if (response.status === 200) {
          mutateUser()
          setAlertState({
            open: true,
            type: 'success',
            message: 'Successfully disabled user',
          })
        } else {
          mutateUser()
          setAlertState({
            open: true,
            type: 'error',
            message: 'Failure to disable user',
          })
        }
        mutateUsers()
      } catch (err) {
        if (err instanceof Error) {
          console.error(err.stack)
        } else {
          console.error(err)
        }
        setAlertState({
          open: true,
          type: 'error',
          message: 'Failure to disable user',
        })
      } finally {
        setDisableLoading(false)
        setDisableOpen(false)
      }
    }

    const handleEnableAccount = async () => {
      setEnableLoading(true)
      try {
        const response = await client.enableUser(user.username)
        if (response.status === 200) {
          mutateUser()
          setAlertState({
            open: true,
            type: 'success',
            message: 'Successfully enabled user',
          })
        } else {
          mutateUser()
          setAlertState({
            open: true,
            type: 'error',
            message: 'Failure to enable user',
          })
        }
        mutateUsers()
      } catch (err) {
        if (err instanceof Error) {
          console.error(err.stack)
        } else {
          console.error(err)
        }
        setAlertState({
          open: true,
          type: 'error',
          message: 'Failure to enable user',
        })
      } finally {
        setEnableLoading(false)
        setEnableOpen(false)
      }
    }

    const handleDeleteAccount = async () => {
      setDeleteLoading(true)
      try {
        const response = await client.deleteUser(user.username)
        history.push('/users')
        if (response.status === 200) {
          mutateUser()
          setAlertState({
            open: true,
            type: 'success',
            message: 'Successfully deleted account',
          })
        } else {
          mutateUser()
          setAlertState({
            open: true,
            type: 'error',
            message: 'Failure to delete account',
          })
        }
        mutateUsers()
      } catch (err) {
        if (err instanceof Error) {
          console.error(err.stack)
        } else {
          console.error(err)
        }
        setAlertState({
          open: true,
          type: 'error',
          message: 'Failure to delete account',
        })
      } finally {
        setDeleteLoading(false)
        setDeleteOpen(false)
      }
    }

    content = (
      <>
        <DisableAccountDialog
          loading={disableLoading}
          username={user.email}
          open={disableOpen}
          onCancel={() => setDisableOpen(false)}
          onAccept={handleDisableAccount}
        />
        <EnableAccountDialog
          loading={enableLoading}
          username={user.email}
          open={enableOpen}
          onCancel={() => setEnableOpen(false)}
          onAccept={handleEnableAccount}
        />
        <DeleteAccountDialog
          loading={deleteLoading}
          username={user.email}
          open={deleteOpen}
          onCancel={() => setDeleteOpen(false)}
          onAccept={handleDeleteAccount}
        />
        <EditAccountDialog
          loading={editLoading}
          open={editOpen}
          user={user}
          onCancel={() => setEditOpen(false)}
          onAccept={handleEditAccept}
        />
        <UserInfoCard
          user={user}
          editUser={handleEditUser}
          openDeleteDialog={() => setDeleteOpen(true)}
          openDisableDialog={() => setDisableOpen(true)}
          openEnableDialog={() => setEnableOpen(true)}
          trustHubChecked={trustHubChecked}
          originatorHubChecked={originatorHubChecked}
          originatorHubPaidChecked={originatorHubPaidChecked}
          dgsChecked={dgsChecked}
        />
      </>
    )
  } else {
    content = <></>
  }

  const handleAppalertClose = () => {
    setAlertState({ ...alertState, open: false })
  }

  return (
    <Container maxWidth="lg">
      <AppAlert {...alertState} onClose={handleAppalertClose} />
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingTop: 48 }}>
          <Nav loading={isLoading} />
        </Grid>
        <Grid item xs={12}>
          <IconButton
            onClick={() => history.goBack()}
            style={{ marginBottom: 40 }}
          >
            <ArrowBackIcon />
          </IconButton>
          {content}
        </Grid>
      </Grid>
    </Container>
  )
}
