import {
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Grid,
  Container,
  CardHeader,
} from '@mui/material'
import React from 'react'

export type KeyboardShortcutsProps = {
  open: boolean
  onClose: () => void
}

export default function KeyboardShortcuts({
  open,
  onClose,
}: KeyboardShortcutsProps): JSX.Element {
  return (
    <div>
      <Dialog
        maxWidth="lg"
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title">
          <Typography
            variant="h2"
            component="h2"
            gutterBottom
            style={{ marginTop: 20 }}
          >
            Global Keyboard Shortcuts
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={3}
              justifyContent="space-evenly"
              alignContent="stretch"
              alignItems="flex-start"
            >
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  component="p"
                  style={{ maxWidth: '75ch' }}
                >
                  These keyboard shortcuts are available across the application
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title="Global"
                    subtitle="Global shortcuts usable across the entire application"
                  />
                  <CardContent>
                    <Typography gutterBottom>
                      Shift + ? - Show the Keyboard Shortcuts Dialog
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title="Users"
                    subtitle="The users management screen"
                  />
                  <CardContent>
                    <Typography gutterBottom>
                      k - Select Previous User
                    </Typography>
                    <Typography gutterBottom>j - Select Next User</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p">
                  For more information.
                  <br />
                  Please contact{' '}
                  <a href="mailto:support@applyproof.com">
                    support@applyproof.com
                  </a>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={onClose}
            color="primary"
            disableFocusRipple
            variant="text"
          >
            Got It.
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
