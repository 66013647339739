import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  FormControl,
  FormHelperText,
  Typography,
  InputLabel,
  MenuItem,
  MenuProps,
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'

import { OriginatorEntity } from '../../../applyproof'
import { DocumentTypes } from '../../../controllers/partnership/types'
import { validationSchema } from './validate'

export type EditOriginatorDialogFields = {
  name: string
  documentTypes: Array<string>
}

export type EditOriginatorDialogProps = {
  loading?: boolean
  originator?: OriginatorEntity | undefined
  originatorDocumentTypes?: DocumentTypes | undefined
  open?: boolean
  onCancel: () => void
  onAccept: (fields: EditOriginatorDialogFields) => Promise<void>
}

export default function EditOriginatorDialog({
  loading,
  originator,
  originatorDocumentTypes,
  open,
  onCancel,
  onAccept,
}: EditOriginatorDialogProps): JSX.Element {
  const initialValues: EditOriginatorDialogFields = {
    name: originator?.organization ?? '',
    documentTypes: originatorDocumentTypes ?? [],
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onAccept,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  })

  useEffect(() => {
    formik.resetForm({
      values: {
        name: originator?.organization ?? '',
        documentTypes: originatorDocumentTypes ?? [],
      },
    })
  }, [open])

  const action = originator ? 'Edit' : 'Create'

  const VerifiableDocumentTypes = [
    'POA',
    'POFS',
    'POLP',
    'POP',
    'CLOA',
    'POTS',
    'POQP',
    'POLP',
    'POE',
  ]

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps: Partial<MenuProps> = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  }

  return (
    <Dialog
      open={Boolean(open)}
      onClose={onCancel}
      aria-labelledby="edit-account-dialog"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="edit-account-dialog">{action} Account</DialogTitle>
        <DialogContent style={{ minHeight: '400px' }}>
          <DialogContentText>
            <Typography component="span" variant="body1">
              {action} account{' '}
              <Typography component="span" variant="h5">
                {originator ? originator.organization : ''}
              </Typography>
            </Typography>
          </DialogContentText>
          <FormControl
            required
            fullWidth
            error={Boolean(formik.errors.name)}
            component="fieldset"
          >
            <TextField
              style={{ minWidth: '48ch' }}
              id="name"
              name="name"
              type="text"
              label="Name"
              fullWidth
              error={Boolean(formik.errors.name)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              inputProps={{
                maxLength: 128,
              }}
            />
            <FormHelperText>{formik.errors.name || ' '}</FormHelperText>
          </FormControl>
          <FormControl
            fullWidth
            required
            error={Boolean(formik.errors.documentTypes)}
            component="fieldset"
          >
            <TextField
              select
              name="documentTypes"
              label="Verifiable Document Types"
              data-cy="select-document-types"
              SelectProps={{
                multiple: true,
                value: formik.values.documentTypes,
                onChange: formik.handleChange,
                MenuProps: MenuProps,
              }}
            >
              {VerifiableDocumentTypes.map((docType) => (
                <MenuItem
                  key={docType}
                  value={docType}
                  style={{ paddingRight: '5px' }}
                >
                  {docType}
                </MenuItem>
              ))}
            </TextField>
            <FormHelperText>
              {formik.errors.documentTypes || ' '}
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            type="reset"
            onClick={onCancel}
            variant="text"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={loading}
            endIcon={loading ? <CircularProgress size={20} /> : undefined}
          >
            {action}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
