import { Button } from '@mui/material'
import { green, red } from '@mui/material/colors'
import React from 'react'

import { OriginatorEntity } from '../../../applyproof'

export type ToggleOriginatorStatusButtonProps = {
  originator: OriginatorEntity
  onDisable: () => void
  onEnable: () => void
}

export default function ToggleOriginatorStatusButton({
  originator,
  onDisable,
  onEnable,
}: ToggleOriginatorStatusButtonProps): JSX.Element {
  const text = originator.enabled ? 'Disable' : 'Enable'
  const callback = originator.enabled ? onDisable : onEnable
  const color = originator.enabled ? red[500] : green[500]
  return (
    <Button
      style={{ color: color, borderColor: color }}
      size="small"
      variant="outlined"
      onClick={callback}
    >
      {text}
    </Button>
  )
}
