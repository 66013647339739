import { Button, Grid, LinearProgress, Typography } from '@mui/material'
import React, { Fragment } from 'react'

import { useAuth } from '../auth-provider'

export type NavProps = {
  loading?: boolean
}

export function Nav({ loading }: NavProps): JSX.Element {
  const auth = useAuth()

  const handleLogout = async () => {
    if (auth) {
      await auth.onLogout()
    } else {
      console.debug('unable to logout properly')
    }
  }

  return (
    <Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <img
                alt="applyproof-logo"
                src="/Sidebar_ApplyProof_logo.svg"
                width={32}
                height={32}
              />
            </Grid>
            <Grid item style={{ marginTop: -8 }}>
              <Typography component="span" variant="h4">
                ApplyProof Account Management
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          alignItems="center"
          style={{ backgroundColor: '#FAFAFA', borderRadius: 5 }}
        >
          <Grid item>
            <Button href="/users" variant="text">
              Users
            </Button>
          </Grid>
          <Grid item>
            <Button href="/originators" variant="text">
              Accounts
            </Button>
          </Grid>
          <Grid item>
            <Button href="/" variant="text" onClick={handleLogout}>
              Logout
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <LinearProgress style={{ visibility: loading ? 'visible' : 'hidden' }} />
    </Fragment>
  )
}
