import {
  ArrowRight as ArrowRightIcon,
  ArrowLeft as ArrowLeftIcon,
} from '@mui/icons-material'
import {
  Alert,
  Container,
  Grid,
  IconButton,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import { ApplyProofUser } from '../applyproof/user'
import QuickUserInfo from '../components/account/quick-account-info'
import { Nav } from '../components/nav'
import PleaseContactSupport from '../components/please-contact-support'
import UserActionBar from '../components/user-action-bar'
import UserList from '../components/user-list'
import useUsers from '../data/UsersData'

export default function RootUsersPage(): JSX.Element {
  const [userFilter, setUserFilter] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<ApplyProofUser | undefined>(
    undefined
  )
  const [accountFilter, setAccountFilter] = useState<string>('All Accounts')
  const [showEnabled, setShowEnabled] = useState(true)
  const [showDisabled, setShowDisabled] = useState(true)

  const [pageNumber, setPageNumber] = useState(1)

  const usersPerPage = 60

  const { data, isLoading, isError } = useUsers(
    pageNumber,
    userFilter,
    accountFilter === 'All Accounts' ? '' : accountFilter
  )

  const usersList = data?.data.users
    .map((user) => new ApplyProofUser(user))
    .filter(
      (user) => (user.enabled && showEnabled) || (!user.enabled && showDisabled)
    )

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1)
    window.scrollTo(0, 0)
  }

  const handlePrevPage = () => {
    setPageNumber(pageNumber - 1)
    window.scrollTo(0, 0)
  }

  const users = !isError ? (
    <UserList
      loading={isLoading}
      users={usersList}
      onSelectUser={(user) =>
        user ? setSelectedUser(user) : setSelectedUser(undefined)
      }
    />
  ) : (
    <Alert severity="error">
      Failure to load. <PleaseContactSupport />
    </Alert>
  )

  const quickUserInfoClose = () => {
    setSelectedUser(undefined)
  }

  const quickUserInfo = (
    <QuickUserInfo user={selectedUser} onClose={quickUserInfoClose} />
  )

  const resetPage = () => {
    setPageNumber(1)
  }

  const isLastPage: boolean =
    (data && data?.data.users.length !== usersPerPage) ?? false

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingTop: 48 }}>
          <Nav loading={isLoading} />
        </Grid>
        <Grid item xs={12}>
          <UserActionBar
            onEmailFilter={(filter) => {
              setUserFilter(filter)
              resetPage()
            }}
            onAccountFilter={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAccountFilter(event.target.value)
              resetPage()
            }}
            setShowDisabled={(disabled) => {
              setShowDisabled(disabled)
              resetPage()
            }}
            setShowEnabled={(enabled) => {
              setShowEnabled(enabled)
              resetPage()
            }}
          />
        </Grid>
        <Grid item xs={7}>
          {users}
        </Grid>
        <Grid item xs={5}>
          {quickUserInfo}
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={4} alignItems="center">
            <Grid item>
              <IconButton onClick={handlePrevPage} disabled={pageNumber === 1}>
                <ArrowLeftIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography>
                {isLastPage ? `${pageNumber}` : `${pageNumber} ...`}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleNextPage} disabled={isLastPage}>
                <ArrowRightIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
