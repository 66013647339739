import useSWR from 'swr'

import {
  createOriginatorClient,
  originatorKeys,
} from '../controllers/partnership'

export default function useOriginators(filter: string) {
  const client = createOriginatorClient()

  const key = originatorKeys.listOriginators('')
  const { data, error, mutate } = useSWR(key, () => client.listOriginators(), {
    revalidateOnMount: true,
  })
  const loading = !data

  return {
    data,
    isError: error,
    isLoading: loading,
    mutateOriginators: mutate,
  }
}
