import Axios, { AxiosInstance } from 'axios'

import { AuthClient } from './auth'
import { LocalCognitoStorage } from './auth/storage'

export async function createAuthedClient(): Promise<AxiosInstance> {
  const auth = new AuthClient()
  await auth.refreshIfExpired()

  const storage = new LocalCognitoStorage()
  const id = storage.getItem('idToken')

  return Axios.create({
    // withCredentials: true,
    headers: {
      Authorization: `Bearer ${id}`,
    },
  })
}
