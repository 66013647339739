import { AxiosResponse } from 'axios'

import { config } from '../../config'
import { createAuthedClient } from '../createAuthedClient'
import {
  ConfirmResetPasswordResponse,
  CreateUserParams,
  CreateUserResponse,
  DisableUserResponse,
  EditAccountResponse,
  EditEmailResponse,
  EditNameResponse,
  EditPhoneResponse,
  EditUserParams,
  EnableUserResponse,
  GetUserResponse,
  ListUsersParams,
  ListUsersResponse,
  ResetPasswordResponse,
  Scopes,
  ScopesResponse,
  UserResponse,
} from './types'

export interface PartnershipClientProps {
  baseUrl: string
}

export function createPartnershipClient(): PartnershipClient {
  return new PartnershipClient({ baseUrl: config.hostedZoneName })
}

export class PartnershipClient {
  private readonly baseUrl: string

  constructor(props: PartnershipClientProps) {
    this.baseUrl = props.baseUrl
  }
  // TODO use export service
  // async export(): Promise<void> {
  //   console.log('waiting for')
  //   const url =  `/user/export/csv`
  //   const client = await createAuthedClient()
  //   client({
  //
  //     url: url,
  //   }).then((response) => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]))
  //     const link = document.createElement('a')
  //     link.href = url
  //     link.setAttribute('download', 'accounts.csv')
  //     document.body.appendChild(link)
  //     link.click()
  //   })
  // }

  async listUsers(
    params: ListUsersParams
  ): Promise<AxiosResponse<ListUsersResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/user`
    const client = await createAuthedClient()
    return client({
      url: url,
      params: params,
    }) as Promise<AxiosResponse<ListUsersResponse>>
  }

  async getUser(userId: string): Promise<AxiosResponse<GetUserResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/user/${userId}`
    const client = await createAuthedClient()
    return client({
      url: url,
    }) as Promise<AxiosResponse<GetUserResponse>>
  }

  async createUser(
    params: CreateUserParams
  ): Promise<AxiosResponse<CreateUserResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/user`
    const client = await createAuthedClient()
    return client({
      method: 'POST',
      url,
      data: params,
    }) as Promise<AxiosResponse<CreateUserResponse>>
  }

  async confirmUser(
    username: string,
    temporaryPassword: string,
    newPassword: string
  ): Promise<AxiosResponse<CreateUserResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/user/${username}/confirm`
    const client = await createAuthedClient()
    return client({
      method: 'POST',
      url,
      data: {
        temporaryPassword,
        newPassword,
      },
    }) as Promise<AxiosResponse<CreateUserResponse>>
  }

  async editUser(
    username: string,
    params: EditUserParams
  ): Promise<AxiosResponse<UserResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/user/${username}`
    const client = await createAuthedClient()
    const data = params
    return client({
      method: 'PATCH',
      url,
      data,
    }) as Promise<AxiosResponse<UserResponse>>
  }

  async editAccount(
    username: string,
    accountId: string
  ): Promise<AxiosResponse<EditAccountResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/user/${username}`
    const client = await createAuthedClient()
    return client({
      method: 'PATCH',
      url,
      data: {
        accountId,
      },
    }) as Promise<AxiosResponse<EditAccountResponse>>
  }

  async editName(
    username: string,
    firstName: string,
    lastName: string
  ): Promise<AxiosResponse<EditNameResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/user/${username}`
    const client = await createAuthedClient()
    return client({
      method: 'PATCH',
      url,
      data: {
        firstName,
        lastName,
      },
    }) as Promise<AxiosResponse<EditNameResponse>>
  }

  async editPhoneNumber(
    username: string,
    phone: string
  ): Promise<AxiosResponse<EditPhoneResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/user/${username}`
    const client = await createAuthedClient()
    return client({
      method: 'PATCH',
      url,
      data: {
        phone,
      },
    }) as Promise<AxiosResponse<EditPhoneResponse>>
  }

  async editEmail(
    username: string,
    email: string
  ): Promise<AxiosResponse<EditEmailResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/user/${username}`
    const client = await createAuthedClient()
    return client({
      method: 'PATCH',
      url,
      data: {
        email,
      },
    }) as Promise<AxiosResponse<EditEmailResponse>>
  }

  async getUserScopes(
    username: string
  ): Promise<AxiosResponse<ScopesResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/scopes/${username}`
    const client = await createAuthedClient()
    return client({
      method: 'GET',
      url,
    }) as Promise<AxiosResponse<ScopesResponse>>
  }

  async editUserScopes(
    username: string,
    scopes: Scopes
  ): Promise<AxiosResponse<ScopesResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/scopes/${username}`
    const client = await createAuthedClient()
    return client({
      method: 'PUT',
      url,
      data: {
        scopes,
      },
    }) as Promise<AxiosResponse<ScopesResponse>>
  }

  async enableUser(
    username: string
  ): Promise<AxiosResponse<EnableUserResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/user/${username}`
    const client = await createAuthedClient()
    return client({
      method: 'PATCH',
      url,
      data: {
        enabled: true,
      },
    }) as Promise<AxiosResponse<EnableUserResponse>>
  }

  async disableUser(
    username: string
  ): Promise<AxiosResponse<DisableUserResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/user/${username}`
    const client = await createAuthedClient()
    return client({
      method: 'PATCH',
      url,
      data: {
        enabled: false,
      },
    }) as Promise<AxiosResponse<DisableUserResponse>>
  }

  async deleteUser(
    username: string
  ): Promise<AxiosResponse<DisableUserResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/user/${username}`
    const client = await createAuthedClient()
    return client({
      method: 'DELETE',
      url,
    }) as Promise<AxiosResponse<DisableUserResponse>>
  }

  async resetPassword(
    username: string
  ): Promise<AxiosResponse<ResetPasswordResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/${username}/reset-password`
    const client = await createAuthedClient()
    return client({
      method: 'POST',
      url,
    }) as Promise<AxiosResponse<ResetPasswordResponse>>
  }

  async confirmResetPassword(
    username: string,
    confirmationCode: string,
    newPassword: string
  ): Promise<AxiosResponse<ConfirmResetPasswordResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/${username}/confirm-reset-password`
    const client = await createAuthedClient()
    return client({
      method: 'POST',
      url,
      data: {
        confirmationCode,
        newPassword,
      },
    }) as Promise<AxiosResponse<ConfirmResetPasswordResponse>>
  }
}

export function generatePayload(params: any) {
  let dataPayload = {}
  for (const prop in params) {
    dataPayload = { ...dataPayload, ...(prop ? { prop: params[prop] } : {}) }
  }
  return dataPayload
}
