export type FormattedPhone = {
  phone?: string
}

export function formatPhoneNumber(phone: string | undefined): FormattedPhone {
  if (phone) {
    if (phone[0] !== '+') {
      return { phone: `+${phone}` }
    }
    return { phone }
  }
  return {}
}
