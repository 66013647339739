import { useHistory } from 'react-router-dom'

import { useAuth } from '../auth-provider'

interface AuthRouteInterface {
  children: JSX.Element
}
export default function AuthRoute({
  children,
}: AuthRouteInterface): JSX.Element {
  const auth = useAuth()
  const history = useHistory()

  if (!auth.activeSession()) {
    history.push('/')
  }

  return children
}
