import {
  CognitoIdentityProvider,
  InitiateAuthCommandOutput,
} from '@aws-sdk/client-cognito-identity-provider'

import { config } from '../../config'
import { LocalCognitoStorage } from './storage'
import {
  AuthenticateParams,
  CompletedAuthResponse,
  ConfirmPasswordParams,
  ForgotPasswordParams,
} from './types'

export class AuthClient {
  private readonly cognito: CognitoIdentityProvider
  private storage: LocalCognitoStorage

  constructor() {
    this.cognito = new CognitoIdentityProvider({
      region: config.cognitoRegion,
    })
    this.storage = new LocalCognitoStorage()
  }

  /**
   * Used to check if user session is active (if jwt token has not expired)
   * Usually used on an interval to check if session is active.
   * Used because we do not have access to cookies, which are
   * secure and http-only.
   * @throws AxiosError if no session active
   */

  async refreshIfExpired(): Promise<void> {
    let auth: InitiateAuthCommandOutput
    try {
      const idToken = this.storage.getIdToken()
      if (this.isExpired(idToken)) {
        const refreshToken = this.storage.getRefreshToken()
        auth = await this.cognito.initiateAuth({
          AuthFlow: 'REFRESH_TOKEN_AUTH',
          ClientId: config.cognitoClientId,
          AuthParameters: {
            REFRESH_TOKEN: refreshToken,
          },
        })
      } else {
        return
      }
    } catch (err) {
      throw new Error('Unable to refresh token')
    }
    if (
      auth.AuthenticationResult &&
      auth.AuthenticationResult.IdToken &&
      auth.AuthenticationResult.RefreshToken
    ) {
      console.log('New id token')
      const idToken = auth.AuthenticationResult.IdToken
      console.log(idToken)
      const refreshToken = auth.AuthenticationResult.RefreshToken

      this.storage.setIdToken(idToken)
      this.storage.setRefreshToken(refreshToken)

      return
    } else {
      throw new Error('Authentication failed. Failed to refresh token')
    }
  }

  async checkSessionActive(): Promise<boolean> {
    try {
      const idToken = this.storage.getIdToken()
      return !this.isExpired(idToken)
    } catch (err) {
      return false
    }
  }

  async authenticate({
    username,
    password,
  }: AuthenticateParams): Promise<CompletedAuthResponse> {
    let auth: InitiateAuthCommandOutput
    try {
      auth = await this.cognito.initiateAuth({
        AuthFlow: 'USER_PASSWORD_AUTH',
        ClientId: config.cognitoClientId,
        AuthParameters: {
          USERNAME: username,
          PASSWORD: password,
        },
      })
    } catch (err) {
      console.error(err)
      const error = err as Error
      if (error.name === 'UserNotFoundException') {
        throw new Error('User does not exist.')
      } else if (error.name === 'NotAuthorizedException') {
        throw new Error('Incorrect username or password.')
      } else if (error.name === 'InvalidParameterException') {
        throw new Error('Username or password failed validation.')
      }
      throw new Error('Failure to login.')
    }

    if (
      auth.AuthenticationResult &&
      auth.AuthenticationResult.IdToken &&
      auth.AuthenticationResult.RefreshToken
    ) {
      const idToken = auth.AuthenticationResult.IdToken
      const refreshToken = auth.AuthenticationResult.RefreshToken

      this.storage.setIdToken(idToken)
      this.storage.setRefreshToken(refreshToken)

      return {
        idToken: idToken,
        refreshToken: refreshToken,
      }
    } else {
      throw new Error('Login failed')
    }
  }

  async logOut(): Promise<void> {
    this.storage.clear()
  }

  isExpired(idToken: string): boolean {
    if (!idToken) {
      throw new Error('No current active session.')
    }

    const expiryDate = parseJwt(idToken).exp

    const expiredSession = Date.now() > expiryDate * 1000

    return expiredSession
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async forgotPassword({ email }: ForgotPasswordParams): Promise<void> {
    throw new Error('not implemented error')
  }

  async confirmPassword({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    code,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    newPassword,
  }: ConfirmPasswordParams): Promise<void> {
    throw new Error('not implemented error')
  }
}

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}
