import {
  DgsScopes,
  OriginatorHubPaidScopes,
  OriginatorHubScopes,
  Scopes,
  TrustHubScopes,
} from '../controllers/partnership/types'

export type EnabledScopes = {
  trustHub: boolean
  originatorHub: boolean
  originatorHubPaid: boolean
  dgs: boolean
}

export const hasTrustHubScopes = (scopes: Scopes): boolean => {
  if (!scopes) {
    return false
  }
  const trustHubPermission = TrustHubScopes.every((scope) =>
    scopes.includes(scope)
  )
  return trustHubPermission
}

export const hasDgsScopes = (scopes: Scopes): boolean => {
  if (!scopes) {
    return false
  }

  return DgsScopes.every((scope) => scopes.includes(scope))
}

export const hasOriginatorHubScopes = (scopes: Scopes): boolean => {
  if (!scopes) {
    return false
  }
  const originatorHubPermission = OriginatorHubScopes.every((scope) =>
    scopes.includes(scope)
  )
  return originatorHubPermission
}

export const hasOriginatorHubPaidScopes = (scopes: Scopes): boolean => {
  if (!scopes) {
    return false
  }
  const originatorHubPaidPermission = OriginatorHubPaidScopes.every((scope) =>
    scopes.includes(scope)
  )
  return originatorHubPaidPermission
}

export function setScopes(scopes: EnabledScopes): Scopes {
  let userScopes: Scopes = []
  if (scopes.trustHub) {
    userScopes = userScopes.concat(TrustHubScopes)
  }
  if (scopes.originatorHub) {
    userScopes = userScopes.concat(OriginatorHubScopes)
  }
  if (scopes.originatorHubPaid) {
    userScopes = userScopes.concat(OriginatorHubPaidScopes)
  }
  if (scopes.dgs) {
    userScopes = userScopes.concat(DgsScopes)
  }
  return userScopes
}
