import { Container, Grid, Pagination } from '@mui/material'
import React, { useContext, useState } from 'react'

import { OriginatorEntity } from '../applyproof'
import { Nav } from '../components/nav'
import OriginatorActionBar from '../components/originator-action-bar'
import { OriginatorContext } from '../components/originator-context'
import OriginatorList from '../components/originator-list'
import QuickOriginatorInfo from '../components/originator/quick-originator-info'

export default function RootOriginatorsPage(): JSX.Element {
  const [filter, setFilter] = useState<string>('')
  const [selectedOriginator, setSelectedOriginator] = useState<
    OriginatorEntity | undefined
  >(undefined)
  const [showEnabled, setShowEnabled] = useState(true)
  const [showDisabled, setShowDisabled] = useState(true)

  const [page, setPage] = useState(1)
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value)
  }

  const originatorsPerPage = 10

  const allOriginators = useContext(OriginatorContext)

  const loading = allOriginators.length === 0

  const originatorsList = allOriginators
    /** Find case-insensitive name matches */
    .filter(
      (originator) =>
        (originator.enabled && showEnabled) ||
        (!originator.enabled && showDisabled)
    )
    .filter((originator) =>
      originator.organization.toLowerCase().includes(filter.toLowerCase())
    )
    /** Sort originators alphabetically by name */
    .sort((a, b) => ('' + a.organization).localeCompare('' + b.organization))

  const numPages = originatorsList
    ? Math.ceil(originatorsList.length / originatorsPerPage)
    : 0

  const originators = (
    <OriginatorList
      loading={loading}
      originators={originatorsList.slice(
        (page - 1) * originatorsPerPage,
        page * originatorsPerPage
      )}
      onSelectOriginator={(originator) =>
        originator
          ? setSelectedOriginator(originator)
          : setSelectedOriginator(undefined)
      }
    />
  )

  const quickOriginatorInfoClose = () => {
    setSelectedOriginator(undefined)
  }

  const quickOriginatorInfo = (
    <QuickOriginatorInfo
      originator={selectedOriginator}
      onClose={quickOriginatorInfoClose}
    />
  )

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingTop: 48 }}>
          <Nav loading={loading} />
        </Grid>
        <Grid item xs={12}>
          <OriginatorActionBar
            onNameFilter={(filter) => {
              setFilter(filter)
              setPage(1)
            }}
            setShowDisabled={(disabled) => {
              setShowDisabled(disabled)
              setPage(1)
            }}
            setShowEnabled={(enabled) => {
              setShowEnabled(enabled)
              setPage(1)
            }}
          />
        </Grid>
        <Grid item xs={7}>
          {originators}
        </Grid>
        <Grid item xs={5}>
          {quickOriginatorInfo}
        </Grid>
        <Grid item xs={7}>
          <Pagination
            style={{
              paddingTop: '0.15rem',
              paddingBottom: '3rem',
            }}
            count={numPages}
            page={page}
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
