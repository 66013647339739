import useSWR from 'swr'

import {
  createOriginatorClient,
  originatorKeys,
} from '../controllers/partnership'

export default function useOriginator(guid: string) {
  const client = createOriginatorClient()

  const key = originatorKeys.getOriginator(guid)

  const fetcher = async () => {
    const originatorData = await client.getOriginator({ guid })
    const originatorDocumentTypes = await client.getDocumentTypes(guid)
    return {
      ...originatorData,
      documentTypes: originatorDocumentTypes.data.documentTypes,
    }
  }
  const { data, error, mutate } = useSWR(key, fetcher, {
    revalidateOnMount: true,
  })
  const loading = !data

  return {
    data,
    isError: error,
    isLoading: loading,
    mutateOriginator: mutate,
  }
}
