import {
  Edit as EditIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@mui/icons-material'
import {
  List,
  Skeleton,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Card,
  CardContent,
  Tooltip,
  Typography,
  createTheme,
} from '@mui/material'
import { red } from '@mui/material/colors'
import React, { useContext, useRef, useState } from 'react'
import { useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { originatorLabelByGuid } from '../../applyproof/originators'
import { ApplyProofUser, ApplyProofUserList } from '../../applyproof/user'
import { colors } from '../../theme/color'
import AccountAvatar from '../account/account-avatar'
import { OriginatorContext } from '../originator-context'

const theme = createTheme()
const classes = {
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    width: '100%',
    height: '4rem',
  },
}

export type UserListProps = {
  loading: boolean
  users: ApplyProofUserList | undefined
  onSelectUser?: (user: ApplyProofUser | undefined) => void | undefined
}

export default function UserList({
  loading,
  users,
  onSelectUser,
}: UserListProps): JSX.Element {
  const [selectedUser, setSelectedUser] = useState<ApplyProofUser | undefined>()
  const usersRef = useRef<ApplyProofUserList | undefined>(undefined)
  usersRef.current = users

  useEffect(() => {
    if (onSelectUser) {
      onSelectUser(selectedUser)
    }
  }, [selectedUser])

  useEffect(() => {
    // selectRelativeUser(0)
  }, [users])

  const selectRelativeUser = (offset: number): void => {
    const users = usersRef.current
    if (users) {
      setSelectedUser((selectedUser) => {
        if (selectedUser) {
          const existingIndex =
            users.findIndex(
              (user) => user.username === selectedUser.username
            ) ?? 0
          const nextIndex = Math.max(
            0,
            Math.min(existingIndex + offset, users.length - 1)
          )
          return users[nextIndex]
        } else {
          return users[offset >= 0 ? 0 : users.length - 1]
        }
      })
    } else {
      return setSelectedUser(undefined)
    }
  }

  useHotkeys('k', () => selectRelativeUser(-1))
  useHotkeys('j', () => selectRelativeUser(1))

  const originators = useContext(OriginatorContext)

  const userList =
    users &&
    users.length > 0 &&
    users.map((user: ApplyProofUser, i: number) => {
      const email = user.email ?? `<No Email> - (${user.username})`
      const organization = originatorLabelByGuid(originators, user.accountId)
      const labelId = `checkbox-list-label-${user.username}`

      const selected = user.username === selectedUser?.username

      return (
        <ListItem
          sx={classes.item}
          key={user.username ?? i}
          role={undefined}
          button
          style={
            selected ? { backgroundColor: colors.secondary.A500 } : undefined
          }
          onClick={() => setSelectedUser(user)}
        >
          <ListItemIcon>
            <AccountAvatar user={user} />
          </ListItemIcon>
          <ListItemText
            id={labelId}
            primary={
              <Typography
                style={{ userSelect: 'text' }}
                component="span"
                variant="body1"
                noWrap={true}
              >
                {email.length > 40 ? `${email.substring(0, 40)} ...` : email}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <Typography component="span" variant="body1">
              {organization}
            </Typography>
            {user.confirmationStatus === 'FORCE_CHANGE_PASSWORD' ? (
              <Tooltip title="User has not changed password.">
                <IconButton
                  style={{ marginLeft: 12, color: red[500] }}
                  edge="end"
                  aria-label="edit"
                >
                  <ErrorOutlineIcon />
                </IconButton>
              </Tooltip>
            ) : undefined}
            <IconButton
              style={{ marginLeft: 12 }}
              edge="end"
              aria-label="edit"
              href={`/users/${user.username}`}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )
    })

  return (
    <>
      <List sx={classes.root}>
        {(!loading && userList) ?? (
          <Card>
            <CardContent>No Users found</CardContent>
          </Card>
        )}
        {loading
          ? [1, 2, 3, 4, 5].map((i) => (
              <Skeleton
                key={i}
                variant="rectangular"
                animation="wave"
                style={{ height: '3rem', marginBottom: '0.5rem' }}
              />
            ))
          : undefined}
      </List>
    </>
  )
}
