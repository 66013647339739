import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material'
import {
  Box,
  Tooltip,
  Button,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ApplyProofUser } from '../../applyproof/user'
import { createPartnershipClient } from '../../controllers/partnership'
import { formatPhoneNumber } from '../../utils/formatPhone'
import { setScopes } from '../../utils/scopes'
import EditAccountDialog, {
  EditAccountDialogFields,
} from '../account/edit-account'
import AppAlert, { AlertState } from '../app-alert'
import { OriginatorContext } from '../originator-context'
import ShowEnabledDisabled from '../show-enabled-disabled'
import { FilterAccountsDropdown } from './styles'

const classes = {
  root: {
    display: 'flex',
  },
}

export type UserActionBarProps = {
  onAccountCreated?: (user: ApplyProofUser) => void
  onEmailFilter?: (filter: string) => void
  onAccountFilter?: (event: React.ChangeEvent<HTMLInputElement>) => void
  setShowEnabled?: (showEnabled: boolean) => void
  setShowDisabled?: (showDisabled: boolean) => void
}

export default function UserActionBar({
  onAccountCreated = () => void 0,
  onEmailFilter = () => void 0,
  onAccountFilter = () => void 0,
  setShowEnabled = () => void 0,
  setShowDisabled = () => void 0,
}: UserActionBarProps): JSX.Element {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    type: 'success',
    message: '',
  })

  const originators = useContext(OriginatorContext).sort((a, b) =>
    a.organization > b.organization ? 1 : -1
  )

  const handleCreateCancel = () => {
    setCreateOpen(false)
  }

  const client = createPartnershipClient()
  const handleCreateAccept = async (
    fields: EditAccountDialogFields
  ): Promise<void> => {
    try {
      setLoading(true)
      const createUserParams = {
        email: fields.email,
        ...formatPhoneNumber(fields.phone),
        firstName: fields.firstName,
        lastName: fields.lastName,
        accountId: fields.accountId,
        scopes: setScopes({
          trustHub: fields.trustHub,
          originatorHub: fields.originatorHub,
          originatorHubPaid: fields.originatorHubPaid,
          dgs: fields.dgs,
        }),
      }
      const response = await client.createUser(createUserParams)

      if (response.status === 200) {
        const user = new ApplyProofUser(response.data, createUserParams.scopes)
        onAccountCreated(user)
        history.push(`/users/${user.username}`)
        setAlertState({
          open: true,
          type: 'success',
          message: 'Successfully created user',
        })
      } else {
        setAlertState({
          open: true,
          type: 'error',
          message: 'Failure to create user. Do they already exist?',
        })
        console.error('failure to create user')
      }
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.stack)
      } else {
        console.error(err)
      }
      setAlertState({
        open: true,
        type: 'error',
        message: 'Failure to create user',
      })
    } finally {
      setLoading(false)
      setCreateOpen(false)
    }
  }

  const handleAlertClose = () => {
    setAlertState({
      open: false,
      type: alertState.type,
      message: alertState.message,
    })
  }

  const [emailFilter, setEmailFilter] = useState('')

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailFilter(e.target.value)
    onEmailFilter(e.target.value)
  }

  const [showEnabled, setShowEnabledLocal] = useState(true)
  const [showDisabled, setShowDisabledLocal] = useState(true)

  const handleShowEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowEnabled(e.target.checked) // update in parent element
    setShowEnabledLocal(e.target.checked)
  }

  const handleShowDisabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDisabled(e.target.checked)
    setShowDisabledLocal(e.target.checked)
  }

  return (
    <Box sx={classes.root}>
      <AppAlert {...alertState} onClose={handleAlertClose} />
      <EditAccountDialog
        loading={loading}
        open={createOpen}
        onCancel={handleCreateCancel}
        onAccept={handleCreateAccept}
      />
      <Grid container spacing={3} alignContent="center" alignItems="center">
        <Grid item>
          <TextField
            label="Search..."
            value={emailFilter}
            onChange={handleFilterChange}
          ></TextField>
        </Grid>
        <Grid item>
          <FilterAccountsDropdown
            data-cy="select-account-filter"
            select
            onChange={onAccountFilter}
            label="Account"
            fullWidth
            InputLabelProps={{ shrink: true }}
            defaultValue={'All Accounts'}
          >
            <MenuItem value="All Accounts">
              <em>All Accounts</em>
            </MenuItem>
            {originators.map((originator) => (
              <MenuItem value={originator.id} key={originator.id}>
                {originator.organization}
              </MenuItem>
            ))}
          </FilterAccountsDropdown>
        </Grid>
        <Grid item>
          <ShowEnabledDisabled
            showDisabled={showDisabled}
            showEnabled={showEnabled}
            handleShowDisabledChange={handleShowDisabledChange}
            handleShowEnabledChange={handleShowEnabledChange}
          />
        </Grid>
        <Grid item>
          <Tooltip title="Create new user">
            <Button
              startIcon={<AddCircleOutlineIcon />}
              variant="text"
              onClick={() => setCreateOpen(true)}
            >
              Create
            </Button>
          </Tooltip>
          {/* <Tooltip title="CSV Export all accounts">
            <Button
              startIcon={<FileDownloadIcon />}
              variant="text"
              onClick={client.export.bind(client)}
            >
              Export
            </Button>
          </Tooltip> */}
        </Grid>
      </Grid>
    </Box>
  )
}
