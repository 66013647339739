import { Grid, Typography, Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'

export type PermissionsCheckboxProps = {
  title: string
  checkboxState: boolean
}

export default function PermissionsCheckbox({
  title,
  checkboxState,
}: PermissionsCheckboxProps): JSX.Element {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary" component="p">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            disabled
            checked={checkboxState}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
      </Grid>
    </>
  )
}
