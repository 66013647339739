import { Box } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'

import LoginPage from '../components/login/login-page'
import { AuthClient } from '../controllers/auth'
import { colors } from '../theme/color'

const classes = {
  root: {
    backgroundColor: colors.login.background,
    minHeight: '100vh',
  },
}

export default function RootLoginPage(): JSX.Element {
  const history = useHistory()
  const client = new AuthClient()

  /**
   * This is a bit janky since technically it gets returned but we redirect
   * asynchronously anyways.
   */
  // if (data) {
  //   history.push('/users')
  // }

  return (
    <Box sx={classes.root}>
      <LoginPage />
    </Box>
  )
}
