import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  createTheme,
} from '@mui/material'
import React, { useState } from 'react'

const theme = createTheme()
const classes = {
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}

export type ShowEnabledDisabledProps = {
  showEnabled: boolean
  showDisabled: boolean
  handleShowEnabledChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleShowDisabledChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export default function ShowEnabledDisabled({
  showEnabled = true,
  showDisabled = true,
  handleShowEnabledChange,
  handleShowDisabledChange,
}: ShowEnabledDisabledProps): JSX.Element {
  return (
    <FormControl component="fieldset" sx={classes.formControl}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={showEnabled}
              onChange={handleShowEnabledChange}
              name="showEnabled"
            />
          }
          label="Show Enabled"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showDisabled}
              onChange={handleShowDisabledChange}
              name="showDisabled"
            />
          }
          label="Show Disabled"
        />
      </FormGroup>
    </FormControl>
  )
}
