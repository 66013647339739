import * as React from 'react'

import ConfirmationDialog from '../../dialog/confirmation'

type DisableAccountDialogProps = {
  loading?: boolean
  username: string
  open?: boolean
  onCancel: () => void
  onAccept: () => void
}

export default function DeleteAccountDialog({
  loading,
  username,
  open,
  onCancel,
  onAccept,
}: DisableAccountDialogProps): JSX.Element {
  const title = 'Delete account?'
  const text = `Delete account ${username}? This is permanent and cannot be reversed.`
  return (
    <ConfirmationDialog
      style="hazardous"
      loading={loading}
      onAccept={onAccept}
      onCancel={onCancel}
      open={open}
      title={title}
      text={text}
    />
  )
}
