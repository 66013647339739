import React, { createContext, useContext } from 'react'

import { AuthClient, CompletedAuthResponse } from '../../controllers/auth'

type AuthProvider = {
  children: JSX.Element
}

interface AuthContextInterface {
  activeSession: () => Promise<boolean>
  onLogin: (username: string, password: string) => Promise<string>
  onLogout: () => Promise<void>
}

const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface
)

export const useAuth = (): AuthContextInterface => {
  return useContext(AuthContext)
}

export default function AuthProvider({ children }: AuthProvider): JSX.Element {
  const auth = new AuthClient()

  const handleLogin = async (username: string, password: string) => {
    const result: CompletedAuthResponse = await auth.authenticate({
      username,
      password,
    })

    return result.idToken
  }

  const handleLogout = async () => {
    await auth.logOut()
  }

  const value = {
    activeSession: auth.checkSessionActive,
    onLogin: handleLogin,
    onLogout: handleLogout,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
