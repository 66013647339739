import { AxiosResponse } from 'axios'

import { OriginatorEntity } from '../../applyproof'
import { config } from '../../config'
import { createAuthedClient } from '../createAuthedClient'
import {
  AssignDocumentTypesReponse,
  CreateOriginatorParams,
  CreateOriginatorResponse,
  DocumentTypes,
  ListOriginatorsResponse,
} from './types'

export interface OriginatorClientProps {
  baseUrl: string
}

export function createOriginatorClient(): OriginatorClient {
  return new OriginatorClient({ baseUrl: config.hostedZoneName })
}

export class OriginatorClient {
  private readonly baseUrl: string

  constructor(props: OriginatorClientProps) {
    this.baseUrl = props.baseUrl
  }

  async listOriginators(): Promise<AxiosResponse<ListOriginatorsResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/account`
    const client = await createAuthedClient()
    return client({
      url: url,
      validateStatus: () => true,
    }) as Promise<AxiosResponse<ListOriginatorsResponse>>
  }

  async getOriginator(params: {
    guid: string
  }): Promise<AxiosResponse<OriginatorEntity>> {
    const url = `https://admin.api.${
      this.baseUrl
    }/v1/admin/account/${encodeURIComponent(params.guid)}`
    const client = await createAuthedClient()
    return client({
      url: url,
      validateStatus: () => true,
    }) as Promise<AxiosResponse<OriginatorEntity>>
  }

  async createOriginator(
    params: CreateOriginatorParams
  ): Promise<AxiosResponse<CreateOriginatorResponse>> {
    const url = `https://admin.api.${this.baseUrl}/v1/admin/account`
    const client = await createAuthedClient()
    return client({
      method: 'POST',
      url,
      data: params,
      validateStatus: () => true,
    }) as Promise<AxiosResponse<CreateOriginatorResponse>>
  }

  async enableOriginator(params: {
    guid: string
  }): Promise<AxiosResponse<CreateOriginatorResponse>> {
    const url = `https://admin.api.${
      this.baseUrl
    }/v1/admin/account/${encodeURIComponent(params.guid)}`
    const data = { enabled: true }
    const client = await createAuthedClient()
    return client({
      method: 'PATCH',
      url,
      data,
      validateStatus: () => true,
    }) as Promise<AxiosResponse<CreateOriginatorResponse>>
  }

  async disableOriginator(params: {
    guid: string
  }): Promise<AxiosResponse<CreateOriginatorResponse>> {
    const url = `https://admin.api.${
      this.baseUrl
    }/v1/admin/account/${encodeURIComponent(params.guid)}`
    const data = { enabled: false }
    const client = await createAuthedClient()
    return client({
      method: 'PATCH',
      url,
      data,
      validateStatus: () => true,
    }) as Promise<AxiosResponse<CreateOriginatorResponse>>
  }

  async editOriginatorName(params: {
    guid: string
    newOrganizationName: string
  }): Promise<AxiosResponse<CreateOriginatorResponse>> {
    const url = `https://admin.api.${
      this.baseUrl
    }/v1/admin/account/${encodeURIComponent(params.guid)}`
    const data = { organization: params.newOrganizationName }
    const client = await createAuthedClient()
    return client({
      method: 'PATCH',
      url,
      data,
      validateStatus: () => true,
    }) as Promise<AxiosResponse<CreateOriginatorResponse>>
  }

  async deleteOriginator(params: {
    guid: string
  }): Promise<AxiosResponse<CreateOriginatorResponse>> {
    const url = `https://admin.api.${
      this.baseUrl
    }/v1/admin/account/${encodeURIComponent(params.guid)}`
    const client = await createAuthedClient()
    return client({
      method: 'DELETE',
      url,
      validateStatus: () => true,
    }) as Promise<AxiosResponse<CreateOriginatorResponse>>
  }

  async assignDocumentTypes(
    documentTypes: DocumentTypes,
    accountId: string
  ): Promise<AxiosResponse<AssignDocumentTypesReponse>> {
    const url = `https://portal.api.${this.baseUrl}/v1/admin/settings/document-types/${accountId}`
    const data = { documentTypes }
    const client = await createAuthedClient()
    return client({
      method: 'POST',
      url,
      data,
      validateStatus: () => true,
    }) as Promise<AxiosResponse<AssignDocumentTypesReponse>>
  }

  async getDocumentTypes(
    accountId: string
  ): Promise<AxiosResponse<AssignDocumentTypesReponse>> {
    const url = `https://portal.api.${this.baseUrl}/v1/admin/settings/document-types/${accountId}`
    const client = await createAuthedClient()
    return client({
      method: 'GET',
      url,
      validateStatus: () => true,
    }) as Promise<AxiosResponse<AssignDocumentTypesReponse>>
  }
}
